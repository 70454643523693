import React from 'react';
import { IonBackButton, IonButtons, IonContent, IonHeader, IonPage, IonRefresher, IonToolbar, RefresherEventDetail, withIonLifeCycle } from '@ionic/react';

//	L O C A L I Z A T I O N

import { locale___App } from '../../../App.locale';

//	S T Y L E

import './sb-discover-feed.scss';

//	S T A T E   -   P R O P S

import { props___SbDiscoverFeedPage, state___SbDiscoverFeedPage, state___SbDiscoverFeedPage___default } from './sb-discover-feed.state';

//	T Y P E S

import { type___api___response } from '../../../types/types.api-response';

//	S E R V I C E S

import { service_RestApiService } from '../../../services/service-api';
import { service_LocalizationService } from '../../../services/service-localization';

//	C O M P O N E N T S

import ComInfiniteScrollerComponent from '../../../components/com-infinite-scroller/com-infinite-scroller';
import ComListEmptyComponent from '../../../components/com-list-empty/com-list-empty';
import ComPostComponent from '../../../components/com-post/com-post';
import ComSpinnerInlineComponent from '../../../components/com-spinner-inline/com-spinner-inline';
import ComTaleComponent from '../../../components/com-tale/com-tale';
import ComTaleCreateComponent from '../../../components/com-tale-create/com-tale-create';

//	M O D A L S

import MdTalesViewModal from '../../../modals/md-tales-view/md-tales-view';

//	C L A S S

class SbDiscoverFeedPage extends React.Component<props___SbDiscoverFeedPage, state___SbDiscoverFeedPage>
{

//#region 																							D E C L A R A T I O N S

	private readonly _API: service_RestApiService = new service_RestApiService();
	private readonly _LOCALE: service_LocalizationService = new service_LocalizationService();

//#endregion

//#region 																							C O N S T R U C T O R

	constructor(
		public props: props___SbDiscoverFeedPage,
	) {
		super(props);
		this.state = state___SbDiscoverFeedPage___default;
	}

//#endregion

//#region 																							R E A D   P R O P S

	read_props___feed_tales = async () => {
		const ___temp___tales: type___api___response = await this._API.tales___read___multi('feed', null, this.state.feed___tales.length);
		this.setState({
			feed___tales: ___temp___tales.data,
			feed___tales___is_loading: false,
			feed___posts___is_complete: true
		});
	}

	read_props___feed_posts = async () => {
		const ___temp___posts: type___api___response = await this._API.posts___read___multi('feed', null, this.state.feed___posts.length);
		this.setState((prevState) => ({
			feed___posts: [...prevState.feed___posts, ...___temp___posts.data],
			feed___posts___is_loading: false,
			feed___posts___is_complete: (___temp___posts.response === 'no-data') ? true : false,
		}), () => {

			console.log(this.state.feed___posts[0])

		});
	}

//#endregion

//#region 																							H A N D L E R S

	handle___page___refresh = (___e: CustomEvent<RefresherEventDetail>) => {
		this.setState({
			feed___tales: [],
			feed___tales___is_loading: true,
			feed___tales___is_complete: false,
			feed___posts: [],
			feed___posts___is_loading: true,
			feed___posts___is_complete: false,
		}, async () => {
			await Promise.all([
				this.read_props___feed_tales(),
				this.read_props___feed_posts(),
			]);
			___e.detail.complete();
		});
	}

//#endregion

//#region 																							L I F E C Y C L E

	ionViewDidEnter() : void {
		this.setState({
			feed___tales: [],
			feed___tales___is_loading: true,
			feed___tales___is_complete: false,
			feed___posts: [],
			feed___posts___is_loading: true,
			feed___posts___is_complete: false,
		}, async () => {
			await Promise.all([
				this.read_props___feed_tales(),
				this.read_props___feed_posts(),
			]);
		});
	}

//#endregion

//#region 																							R E T U R N

	render() : React.ReactNode
	{
		return <>
			<IonPage>

				<MdTalesViewModal isOpen={ this.state.ionModal___MdTalesViewModal___isOpen } tale_id={ this.state.ionModal___MdTalesViewModal___argument } event___onDidDismiss={() => { this.setState({ ionModal___MdTalesViewModal___isOpen: false, ionModal___MdTalesViewModal___argument: null }); }} />

				<IonHeader>
					<IonToolbar>
						<IonButtons slot="start">
							<IonBackButton defaultHref="/discover" text={ this._LOCALE.translate(locale___App.element___IonBackButton) }></IonBackButton>
						</IonButtons>
					</IonToolbar>
				</IonHeader>

				<IonContent forceOverscroll={ false }>

					<IonRefresher slot="fixed" onIonRefresh={ this.handle___page___refresh }>
						<ComSpinnerInlineComponent/>
					</IonRefresher>

					<div className="sb-discover-feed---container---tales">
						<ComTaleCreateComponent is_logged={ this.props.is_logged } is_logged___props={ this.props.is_logged___props } />
						{(this.state.feed___tales___is_loading === true) ? <>
							<ComSpinnerInlineComponent/>
						</> :<>
							{this.state.feed___tales.map((___t: any, ___t___idx: number) => { return <>
								<ComTaleComponent key={ ___t___idx } tale___props={ ___t } event___onClick={() => { this.setState({ ionModal___MdTalesViewModal___isOpen: true, ionModal___MdTalesViewModal___argument: ___t['tale_id'] }) }}/>
							</>})}
						</>}
					</div>

					<div className="sb-discover-feed---container---posts">
						{(this.state.feed___posts___is_loading === true) ? <>
							<ComSpinnerInlineComponent/>
						</> :<>
							{this.state.feed___posts.map((___p: any, ___p___idx: number) => { return <>
								<ComPostComponent key={ ___p___idx } post___type="normal" post___props={ ___p } is_logged={ this.props.is_logged } is_logged___props={ this.props.is_logged___props } post___can_route_comments={ true }/>
							</>})}
						</>}
					</div>

					{(this.state.feed___posts___is_complete === true) ? <>
						<ComListEmptyComponent list_items_quantity={ this.state.feed___posts.length }/>
					</> : <>
						<ComInfiniteScrollerComponent is_loading={ this.state.feed___posts___is_loading } event___scrollTriggered={ this.read_props___feed_posts } />
					</>}

				</IonContent>

			</IonPage>
		</>;
	}

//#endregion

}

//	E X P O R T

export default withIonLifeCycle(SbDiscoverFeedPage);