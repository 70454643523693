import { lib_errors_geolocation } from '../libs/lib.errors.geolocation';
import { lib_names } from '../libs/lib.names';
import { type___geo___lngLat } from '../types/types.types';

//	E X P O R T

export class service_GeolocationService
{

//#region 																							L O C A T E

	geolocation___locate = async () : Promise<type___geo___lngLat> => {
		return new Promise((resolve, reject) => {
			if (!navigator.geolocation) { return resolve({ lat: 0, lng: 0, error: lib_errors_geolocation.geolocation___not_supported }); }
			navigator.geolocation.getCurrentPosition(
				(___position: GeolocationPosition) => {
					const ___me___geolocation: type___geo___lngLat = { lng: ___position.coords.longitude, lat: ___position.coords.latitude, error: null };
					const ___me___geolocation___stringified: string = ___me___geolocation['lng'].toString() + ',' + ___me___geolocation['lat'].toString();
					localStorage.setItem(lib_names.localStorage.user___location, ___me___geolocation___stringified);
					resolve(___me___geolocation);
					return;
				},
				(___error: GeolocationPositionError) => {
					let ___error___errorMessage: string = '';
					switch (___error.code) {
						case ___error.PERMISSION_DENIED: ___error___errorMessage = lib_errors_geolocation.geolocation___denied; break;
						case ___error.POSITION_UNAVAILABLE: ___error___errorMessage = lib_errors_geolocation.geolocation___unavailable; break;
						case ___error.TIMEOUT: ___error___errorMessage = lib_errors_geolocation.geolocation___timed_out; break;
						default: ___error___errorMessage = lib_errors_geolocation.geolocation___generic; break;
					}
					resolve({ lat: 0, lng: 0, error: lib_errors_geolocation.geolocation___generic });
				}
			);
		}) as Promise<type___geo___lngLat>;
	}

//#endregion

}