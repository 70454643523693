import React from 'react';
import { IonAlert, IonBackButton, IonButtons, IonCard, IonContent, IonHeader, IonItem, IonLabel, IonList, IonListHeader, IonPage, IonToolbar } from '@ionic/react';

//	L O C A L I Z A T I O N

import { locale___App } from '../../../App.locale';
import { locale___SbSettingsMainPage } from './sb-settings-main.locale';

//	S T Y L E

import './sb-settings-main.scss';

//	I N T E R F A C E S

import { interface___IonAlert } from '../../../interfaces/interface.alert';

//	S T A T E   -   P R O P S

import { props___SbSettingsPage, state___SbSettingsMainPage, state___SbSettingsMainPage___default } from './sb-settings-main.state';

//	S E R V I C E S

import { service_LocalizationService } from '../../../services/service-localization';
import { service_RestAuthLogoutService } from '../../../services/service-auth-logout';

//	C O M P O N E N T S

import ComCopyrightComponent from '../../../components/com-copyright/com-copyright';

//	M O D A L S

import MdEntryInterestsModal from '../../../modals/md-entry-interests/md-entry-interests';
import MdEntryLanguageModal from '../../../modals/md-entry-language/md-entry-language';

//	C L A S S

export default class SbSettingsMainPage extends React.Component<props___SbSettingsPage, state___SbSettingsMainPage>
{

//#region 																							D E C L A R A T I O N S

	private readonly _LOCALE: service_LocalizationService = new service_LocalizationService();
	private readonly _AUTH_LOGOUT: service_RestAuthLogoutService = new service_RestAuthLogoutService();

//#endregion

//#region 																							C O N F I G

	private readonly ionAlert___askForClose___config: interface___IonAlert = {
		header: 'Stai per effettuare il logout',
		message: 'Vuoi continuare?',
		buttons: [
			{
				text: 'Si',
				handler: () => {
					this._AUTH_LOGOUT.perform___logout();
				}
			},
			{
				text: 'Annulla',
				role: 'cancel',
				handler: () => {
					this.setState({ ionAlert___askForClose___isOpen: false });
				}
			}
		]
	};
		
//#endregion

//#region 																							C O N S T R U C T O R

	constructor(
		public props: props___SbSettingsPage,
	) {
		super(props);
		this.state = state___SbSettingsMainPage___default;
	}

//#endregion

//#region 																							R E N D E R

	render() : React.ReactNode
	{
		return <>
			<IonPage>

				<IonAlert isOpen={ this.state.ionAlert___askForClose___isOpen } {...this.ionAlert___askForClose___config}></IonAlert>

				<MdEntryInterestsModal isOpen={ this.state.ionModal___MdEntryInterestsModal___isOpen } isDismissible={ true } is_logged={ this.props.is_logged } is_logged___props={ this.props.is_logged___props } event___onDidDismiss={() => { this.setState({ ionModal___MdEntryInterestsModal___isOpen: false }); }}/>
				<MdEntryLanguageModal isOpen={ this.state.ionModal___MdEntryLanguageModal___isOpen } isDismissible={ true } event___onDidDismiss={() => { this.setState({ ionModal___MdEntryLanguageModal___isOpen: false }); }}/>

				<IonHeader className="ion-no-border" collapse="fade">
					<IonToolbar>
						<IonButtons slot="start">
							<IonBackButton defaultHref="/discover" text={ this._LOCALE.translate(locale___App.element___IonBackButton) }></IonBackButton>
						</IonButtons>
					</IonToolbar>
				</IonHeader>

				<IonContent forceOverscroll={ false }>

					<section className="sb-settings-main---container">

						<IonListHeader>
							<IonLabel>{ this._LOCALE.translate(locale___SbSettingsMainPage.account) }</IonLabel>
						</IonListHeader>
						<br />
						<IonCard>
							<IonList>
								<IonItem className="is-ion-item-button" button={ true } routerLink="/settings/profile">
									<i className="fa-solid fa-user"></i>
									<IonLabel>{ this._LOCALE.translate(locale___SbSettingsMainPage.account___profile) }</IonLabel>
								</IonItem>
							{/*
								<IonItem className="is-ion-item-button" button={ true } routerLink="/settings/account">
									<i className="fa-solid fa-user"></i>
									<IonLabel>{ this._LOCALE.translate(locale___SbSettingsMainPage.account___account) }</IonLabel>
								</IonItem>
							*/}
								<IonItem className="is-ion-item-button" button={ true } routerLink="/settings/auth">
									<i className="fa-solid fa-lock"></i>
									<IonLabel>{ this._LOCALE.translate(locale___SbSettingsMainPage.account___auth) }</IonLabel>
								</IonItem>
								<IonItem className="is-ion-item-button" button={ true } routerLink="/settings/privacy">
									<i className="fa-solid fa-ghost"></i>
									<IonLabel>{ this._LOCALE.translate(locale___SbSettingsMainPage.account___privacy) }</IonLabel>
								</IonItem>
								<IonItem className="is-ion-item-button" button={ true } onClick={() => { this.setState({ ionAlert___askForClose___isOpen: true }); }}>
									<i className="fas fa-power-off"></i>
									<IonLabel>{ this._LOCALE.translate(locale___SbSettingsMainPage.account___logout) }</IonLabel>
								</IonItem>
							</IonList>
						</IonCard>

						{/*
							<br />
							<IonCard>
								<IonList>
									<IonItem className="is-ion-item-button" button={ true } routerLink="/settings/credits">
										<i className="far fa-credit-card"></i>
										<IonLabel>Crediti e Pagamenti</IonLabel>
									</IonItem>
								</IonList>
							</IonCard>
						*/}

						<br />

						<IonListHeader>
							<IonLabel>{ this._LOCALE.translate(locale___SbSettingsMainPage.preferences) }</IonLabel>
						</IonListHeader>
						<br />
						<IonCard>
							<IonList>
								<IonItem className="is-ion-item-button" button={ true } onClick={() => { this.setState({ ionModal___MdEntryInterestsModal___isOpen: true }); }}>
									<i className="fas fa-icons"></i>
									<IonLabel>{ this._LOCALE.translate(locale___SbSettingsMainPage.preferences___interests) }</IonLabel>
								</IonItem>
								<IonItem className="is-ion-item-button" button={ true } onClick={() => { this.setState({ ionModal___MdEntryLanguageModal___isOpen: true }); }}>
									<img src={ '/assets/localization/locale.' + this._LOCALE.language_get() + '.svg' } />
									<IonLabel>{ this._LOCALE.translate(locale___SbSettingsMainPage.preferences___language) }</IonLabel>
								</IonItem>
							</IonList>
						</IonCard>

						<br />

						<IonListHeader>
							<IonLabel>{ this._LOCALE.translate(locale___SbSettingsMainPage.support) }</IonLabel>
						</IonListHeader>
						<br />
						<IonCard>
							{/**
								<IonItem className="is-ion-item-button" button={ true } routerLink="/info/suggestions">
									<i className="far fa-lightbulb"></i>
									<IonLabel>{ this._LOCALE.translate(locale___SbSettingsMainPage.support___suggest_a_feature) }</IonLabel>
								</IonItem>
							 */}
							<IonList>
								<IonItem className="is-ion-item-button" button={ true } routerLink="/info/support">
									<i className="fa-solid fa-headset"></i>
									<IonLabel>{ this._LOCALE.translate(locale___SbSettingsMainPage.support___contact_us) }</IonLabel>
								</IonItem>
							{/**
								<IonItem className="is-ion-item-button" button={ true } routerLink="/info/faq">
									<i className="fa-solid fa-question-circle"></i>
									<IonLabel>{ this._LOCALE.translate(locale___SbSettingsMainPage.support___faq) }</IonLabel>
								</IonItem>
								<IonItem className="is-ion-item-button" routerLink="/settings/datas/cache" routerDirection="forward">
									<i className="fa-solid fa-database"></i>
									<IonLabel>{ this._LOCALE.translate(locale___SbSettingsMainPage.support___clear_cache) }</IonLabel>
								</IonItem>
							*/}
								<IonItem className="is-ion-item-button" routerLink="/settings/datas" routerDirection="forward">
									<i className="fa-solid fa-database"></i>
									<IonLabel>Gestisci i dati personali</IonLabel>
								</IonItem>
							</IonList>
						</IonCard>

						<br />

						<IonListHeader>
							<IonLabel>{ this._LOCALE.translate(locale___SbSettingsMainPage.about) }</IonLabel>
						</IonListHeader>
					
						<br />

						<IonCard>
							<IonList>
								<IonItem className="is-ion-item-button" routerLink="/info/stats" routerDirection="forward">
									<i className="fa-solid fa-chart-bar"></i>
									<IonLabel>{ this._LOCALE.translate(locale___SbSettingsMainPage.about___stats) }</IonLabel>
								</IonItem>
								<IonItem className="is-ion-item-button" routerLink="/info/eula" routerDirection="forward">
									<i className="fa-solid fa-balance-scale"></i>
									<IonLabel>{ this._LOCALE.translate(locale___SbSettingsMainPage.about___eula) }</IonLabel>
								</IonItem>
								<IonItem className="is-ion-item-button" routerLink="/info/partners" routerDirection="forward">
									<i className="fas fa-crown"></i>
									<IonLabel>{ this._LOCALE.translate(locale___SbSettingsMainPage.about___partners) }</IonLabel>
								</IonItem>
								<IonItem className="is-ion-item-button" routerLink="/info/thanks" routerDirection="forward">
									<i className="fa-solid fa-user-friends"></i>
									<IonLabel>{ this._LOCALE.translate(locale___SbSettingsMainPage.about___thanks) }</IonLabel>
								</IonItem>
								<IonItem className="is-ion-item-button" routerLink="/info/app" routerDirection="forward">
									<i className="fa-solid fa-code-branch"></i>
									<IonLabel>{ this._LOCALE.translate(locale___SbSettingsMainPage.about___version) }</IonLabel>
								</IonItem>
								<IonItem className="is-ion-item-button" routerLink="/info/licences" routerDirection="forward">
									<i className="fa-solid fa-key"></i>
									<IonLabel>{ this._LOCALE.translate(locale___SbSettingsMainPage.about___licences) }</IonLabel>
								</IonItem>
								<IonItem className="is-ion-item-button" routerLink="/info/copyright" routerDirection="forward">
									<i className="fa-solid fa-copyright"></i>
									<IonLabel>{ this._LOCALE.translate(locale___SbSettingsMainPage.about___copyright) }</IonLabel>
								</IonItem>
							</IonList>
						</IonCard>

						<br />
						<br />
						<br />

						<ComCopyrightComponent />

						<br />

					</section>

				</IonContent>

			</IonPage>
		</>;
	}

//#endregion

}