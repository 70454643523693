import React from 'react';
import { IonBackButton, IonButtons, IonCard, IonContent, IonHeader, IonItem, IonLabel, IonList, IonListHeader, IonNote, IonPage, IonToolbar, withIonLifeCycle } from '@ionic/react';

//	L O C A L I Z A T I O N S

import { locale___App } from '../../../App.locale';
import { locale___SbInfoStatsPage } from './sb-info-stats.locale';

//	S T Y L E

import './sb-info-stats.scss';

//	S T A T E   -   P R O P S

import { state___SbInfoStatsPage, state___SbInfoStatsPage___default } from './sb-info-stats.state';

//	T Y P E S

import { type___api___response } from '../../../types/types.api-response';

//	F U N C S

import { funcs_numbers } from '../../../funcs/funcs.numbers';

//	S E R V I C E S

import { service_LocalizationService } from '../../../services/service-localization';
import { service_RestApiService } from '../../../services/service-api';

//	C O M P O N E N T S

import ComSpinnerComponent from '../../../components/com-spinner/com-spinner';

//	C L A S S

class SbInfoStatsPage extends React.Component<{}, state___SbInfoStatsPage>
{

//#region 																							D E C L A R A T I O N S

	private readonly _LOCALE: service_LocalizationService = new service_LocalizationService();
	private readonly _API: service_RestApiService = new service_RestApiService();

	private readonly funcs___numbers: funcs_numbers = new funcs_numbers();

//#endregion

//#region 																							C O N S T R U C T O R

	constructor(
		public props: {},
	) {
		super(props);
		this.state = state___SbInfoStatsPage___default;
	}

//#endregion

//#region 																							R E A D   P R O P S

	private read_props___stats___platform = async () => {
		const ___temp___stats___platform: type___api___response = await this._API.stats___platform();
		if (___temp___stats___platform.response === 'success') {
			this.setState({
				stats_platform___count_events: (___temp___stats___platform.data['count_events']) ?? null, 
				stats_platform___count_poi: (___temp___stats___platform.data['count_poi']) ?? null, 
				stats_platform___count_poi_categories: (___temp___stats___platform.data['count_poi_categories']) ?? null, 
				stats_platform___count_posts: (___temp___stats___platform.data['count_posts']) ?? null, 
				stats_platform___count_tales: (___temp___stats___platform.data['count_tales']) ?? null, 
				stats_platform___count_users: (___temp___stats___platform.data['count_users']) ?? null, 
				stats_platform___props___is_loading: false,
			});
		}
	}

//#endregion

//#region 																							L I F E C Y C L E

	async ionViewDidEnter() : Promise<void>
	{
		await this.read_props___stats___platform();
	}

//#endregion

//#region 																							R E T U R N

	render() : React.ReactNode
	{
		return <>
			<IonPage>

				<IonHeader className="ion-no-border" collapse="fade">
					<IonToolbar>
						<IonButtons slot="start">
							<IonBackButton defaultHref="/discover" text={ this._LOCALE.translate(locale___App.element___IonBackButton) } />
						</IonButtons>
					</IonToolbar>
				</IonHeader>

				<IonContent forceOverscroll={ false }>
					<section className="sb-info-stats---container">

						<img src="../../assets/ui/images/image-analytics.svg"/>

						<br />
						<br />

						{(this.state.stats_platform___props___is_loading === true) ? <>
						
							<br />
							<br />
							<br />

							<ComSpinnerComponent size="small" />
						
						</> : <>

							<IonListHeader>
								<IonLabel style={{textAlign:'center'}}>{ this._LOCALE.translate(locale___SbInfoStatsPage.stats___platform) }</IonLabel>
							</IonListHeader>

							<br />
							<br />
							
							<IonCard>
								<IonList>
									<IonItem className="is-ion-item-button">
										<img src="../../assets/ui/icons/icon-category.svg"/>
										<IonLabel>{ this._LOCALE.translate(locale___SbInfoStatsPage.stats___platform___poi_categories) }</IonLabel>
										<IonNote slot="end">{ this.funcs___numbers.number___format_decimal(this.state.stats_platform___count_poi_categories, 0) }</IonNote>
									</IonItem>
									<IonItem className="is-ion-item-button">
										<img src="../../assets/ui/icons/icon-poi.svg"/>
										<IonLabel>{ this._LOCALE.translate(locale___SbInfoStatsPage.stats___platform___poi) }</IonLabel>
										<IonNote slot="end">{ this.funcs___numbers.number___format_decimal(this.state.stats_platform___count_poi, 0) }</IonNote>
									</IonItem>
									<IonItem className="is-ion-item-button">
										<img src="../../assets/ui/icons/icon-event.svg"/>
										<IonLabel>{ this._LOCALE.translate(locale___SbInfoStatsPage.stats___platform___events) }</IonLabel>
										<IonNote slot="end">{ this.funcs___numbers.number___format_decimal(this.state.stats_platform___count_events, 0) }</IonNote>
									</IonItem>
									{/*
										<IonItem className="is-ion-item-button">
											<img src="../../assets/ui/icons/icon-organization.svg"/>
											<IonLabel>{ this._LOCALE.translate(locale___SbInfoStatsPage.stats___platform___daily_views) }</IonLabel>
											<IonNote slot="end">{ 93876783 }</IonNote>
										</IonItem>
									*/}
								</IonList>
							</IonCard>

							<br />
							<br />

							<IonListHeader>
								<IonLabel style={{textAlign:'center'}}>{ this._LOCALE.translate(locale___SbInfoStatsPage.stats___users) }</IonLabel>
							</IonListHeader>

							<br />
							<br />

							<IonCard>
								<IonList>
									<IonItem className="is-ion-item-button">
										<img src="../../assets/ui/icons/icon-user.svg"/>
										<IonLabel>{ this._LOCALE.translate(locale___SbInfoStatsPage.stats___users___registered) }</IonLabel>
										<IonNote slot="end">{ this.funcs___numbers.number___format_decimal(this.state.stats_platform___count_users, 0) }</IonNote>
									</IonItem>
									<IonItem className="is-ion-item-button">
										<img src="../../assets/ui/icons/icon-tale.svg"/>
										<IonLabel>{ this._LOCALE.translate(locale___SbInfoStatsPage.stats___users___tales) }</IonLabel>
										<IonNote slot="end">{ this.funcs___numbers.number___format_decimal(this.state.stats_platform___count_tales, 0) }</IonNote>
									</IonItem>
									<IonItem className="is-ion-item-button">
										<img src="../../assets/ui/icons/icon-post.svg"/>
										<IonLabel>{ this._LOCALE.translate(locale___SbInfoStatsPage.stats___users___posts) }</IonLabel>
										<IonNote slot="end">{ this.funcs___numbers.number___format_decimal(this.state.stats_platform___count_posts, 0) }</IonNote>
									</IonItem>
									{/*
										<IonItem className="is-ion-item-button">
											<img src="../../assets/ui/icons/icon-star.svg"/>
											<IonLabel>{ this._LOCALE.translate(locale___SbInfoStatsPage.stats___users___reviews) }</IonLabel>
											<IonNote slot="end">{ 2345 }</IonNote>
										</IonItem>
									*/}
								</IonList>
							</IonCard>

						</>}

					</section>
				</IonContent>

			</IonPage>
		</>;

	}

//#endregion

}

//	E X P O R T

export default withIonLifeCycle(SbInfoStatsPage);