import React from 'react';
import { IonBackButton, IonButtons, IonCard, IonContent, IonHeader, IonItem, IonLabel, IonList, IonPage, IonRefresher, IonToolbar, RefresherEventDetail, withIonLifeCycle } from '@ionic/react';
import { Link } from 'react-router-dom';

//	L O C A L I Z A T I O N

import { locale___App } from '../../../App.locale';
import { locale___SbEventViewComponent } from './sb-events-view.locale';

//	S T Y L E

import './sb-events-view.scss';

//	F U N C S

import { funcs_numbers } from '../../../funcs/funcs.numbers';

//	S T A T E   -   P R O P S

import { props___SbEventsViewPage, state___SbEventsViewPage, state___SbEventsViewPage___default } from './sb-events-view.state';

//	T Y P E S

import { type___api___response } from '../../../types/types.api-response';

//	S E R V I C E S

import { service_LocalizationService } from '../../../services/service-localization';
import { service_RestApiService } from '../../../services/service-api';
import { service_Router } from '../../../services/service-router';

//	C O M P O N E N T S

import Com404Component from '../../../components/com-404/com-404';
import ComButtonShareComponent from '../../../components/com-button-share/com-button-share';
import ComEventDisclaimerViewComponent from '../../../components/com-event-disclaimer-view/com-event-disclaimer-view';
import ComEventInviteComponent from '../../../components/com-event-invite/com-event-invite';
import ComEventTimeScheduleComponent from '../../../components/com-event-timeschedule/com-event-timeschedule';
import ComGalleryPlaceholderComponent from '../../../components/com-gallery-placeholder/com-gallery-placeholder';
import ComListSeparatorComponent from '../../../components/com-list-separator/com-list-separator';
import ComMapPreviewComponent from '../../../components/com-map-preview/com-map-preview';
import ComMapPreviewAddressComponent from '../../../components/com-map-preview-address/com-map-preview-address';
import ComPoiComponent from '../../../components/com-poi/com-poi';
import ComSpinnerComponent from '../../../components/com-spinner/com-spinner';
import ComSpinnerInlineComponent from '../../../components/com-spinner-inline/com-spinner-inline';
import ComSpinnerRefresherComponent from '../../../components/com-spinner-refresher/com-spinner-refresher';
import ComTextComponent from '../../../components/com-text/com-text';
import ComUserComponent from '../../../components/com-user/com-user';

//	M O D A L S

import MdMediaViewModal from '../../../modals/md-media-view/md-media-view';

//	C L A S S

class SbEventsViewPage extends React.Component<props___SbEventsViewPage, state___SbEventsViewPage>
{

//#region 																							D E C L A R A T I O N S

	private readonly _API: service_RestApiService = new service_RestApiService();
	private readonly _LOCALE: service_LocalizationService = new service_LocalizationService();
	private readonly _ROUTER: service_Router = new service_Router();

	private readonly funcs___numbers: funcs_numbers = new funcs_numbers();

//#endregion

//#region 																							C O N S T R U C T O R

	constructor(
		public props: props___SbEventsViewPage,
	) {
		super(props);
		this.state = state___SbEventsViewPage___default;
	}

//#endregion

//#region 																							R E A D   P R O P S

	private read_props___event = async () => {
		const ___temp___event___props: type___api___response = await this._API.events___read___single(this.state.event___id);
		this.setState({
			event___props: ___temp___event___props.data,
			event___props___is_loading: false,
		}, () => {
			this.setState({
				event___user___props: this.state.event___props['event_user'],
			}, async () => {



				await this.read_props___event___poi();
				await this.read_props___event___user();
				await this.read_props___invite();
			});
		});
	}

	private read_props___invite = async () => {
		if (this.state.event___props['event_invite'] && this.state.event___props['event_invite'] !== null && this.state.event___props['event_invite']['invite_id']) { 
			const ___temp___invite___props: type___api___response = await this._API.events_invites___read___single(this.state.event___props['event_invite']['invite_id']);
			this.setState({
				event___invite: ___temp___invite___props.data,
			});
		}
	}

	private read_props___event___poi = async () => {
		if (this.state.event___props['event_poi']) {
			const ___temp___event_poi___props: type___api___response = await this._API.poi___read___single(this.state.event___props['event_poi']);
			this.setState({
				event___poi___props: ___temp___event_poi___props.data,
			});
		}
	}

	private read_props___event___user = async () => {
		if (this.state.event___props['event_user_public'] && this.state.event___props['event_user_public'] === true) {
			const ___temp___event_poi___props: type___api___response = await this._API.users___read___single('id', this.state.event___props['event_user']['user_id']);
			this.setState({
				event___user___props: ___temp___event_poi___props.data,
			});
		}
	}

//#endregion

//#region 																							H A N D L E R S

	private handle___page___refresh = (___e: CustomEvent<RefresherEventDetail>) => {
		this.setState({
			event___props: null, 
			event___props___is_loading: true,
		}, async () => {
			await this.read_props___event();
			___e.detail.complete();
		});
	}

	private handle___media_preview___show = (___media_src: string) => {
		this.setState({
			ionModal___MdMediaViewModal___argument: ___media_src,
			ionModal___MdMediaViewModal___visible: true,
		});
	}

//#endregion

//#region 																							L I F E C Y C L E

	ionViewDidEnter() : void {
		const ___event_id: string | null = this._ROUTER.get_id(this.props);
		if (___event_id) {
			this.setState({
				event___id: ___event_id,
			}, async () => {
				await this.read_props___event();
			});
		}
	}

//#endregion

//#region 																							R E T U R N

	render() : React.ReactNode
	{
		return <>
			<IonPage>

				<MdMediaViewModal isOpen={ this.state.ionModal___MdMediaViewModal___visible } media_src={ this.state.ionModal___MdMediaViewModal___argument } event___onDidDismiss={() => { this.setState({ ionModal___MdMediaViewModal___visible: false, ionModal___MdMediaViewModal___argument: '' }); }} />

				<IonHeader>
					<IonToolbar>
						<IonButtons slot="start">
							<IonBackButton defaultHref="/discover" text={ this._LOCALE.translate(locale___App.element___IonBackButton) }></IonBackButton>
						</IonButtons>
					</IonToolbar>
				</IonHeader>

				<IonContent forceOverscroll={ false }>

					<IonRefresher slot="fixed" onIonRefresh={ this.handle___page___refresh }>
						<ComSpinnerInlineComponent/>
					</IonRefresher>

					{(this.state.event___props___is_loading === true) ? <>

						<br />
						<br />
						<br />
						<br />
						<br />
						<br />
						<ComSpinnerComponent size="small"/>
				
					</> : <>

						<IonRefresher slot="fixed" onIonRefresh={ this.handle___page___refresh }>
							<ComSpinnerRefresherComponent/>
						</IonRefresher>
				
						{(this.state.event___props !== null) ? <>
							<div className="sb-page---container sb-event-view---container">

								{(this.state.event___props['event_is_mine'] && this.state.event___props['event_is_mine'] === true) ? <>
									<IonCard>
										<IonList>
											<IonItem className="is-ion-item-button" button={ true } routerLink={ '/view/events/' + this.state.event___props['event_id'] + '/notifications' } routerDirection="forward">
												<i className="far fa-bell"></i>
												<IonLabel>{ this._LOCALE.translate(locale___SbEventViewComponent.event_commands___notifications) }</IonLabel>
											</IonItem>
											<IonItem className="is-ion-item-button" button={ true } routerLink={ '/view/events/' + this.state.event___props['event_id'] + '/settings' } routerDirection="forward">
												<i className="fas fa-cog"></i>
												<IonLabel>{ this._LOCALE.translate(locale___SbEventViewComponent.event_commands___settings) }</IonLabel>
											</IonItem>
										</IonList>
									</IonCard>
									<ComListSeparatorComponent />
								</> : <></>}

								{(this.state.event___props['event_invite'] && this.state.event___props['event_invite'] !== null && this.state.event___invite !== null) ? <>
									<ComEventInviteComponent invite_props={ this.state.event___invite }/>
								</> : <></>}

								<ComEventTimeScheduleComponent event_props={ this.state.event___props } />
						
								<div className="sb-event-view---poster">
									{(this.state.event___props['event_poster'] && this.state.event___props['event_poster'].length > 0) ? <>
										<img src={ this.state.event___props['event_poster'] } onClick={() => { this.handle___media_preview___show(this.state.event___props['event_poster']); }}/>
									</> : <>
										<ComGalleryPlaceholderComponent placeholder_path={ '/assets/markers/event/' + this.state.event___props['event_category']['event_category_icon'] + '.svg' } />
									</>}
								</div>

								<h6>
									<Link to={ '/view/events/category/' + this.state.event___props['event_category']['event_category_id'] }>{ this.state.event___props['event_category']['event_category_name'] }</Link>
									<span>{ this._LOCALE.translate(locale___SbEventViewComponent.distance_from_you, [this.funcs___numbers.number___format_as_string___distance(this.state.event___props['event_coords_distance'])]) }</span>
								</h6>

								<h3>{ this.state.event___props['event_name'] }</h3>

								{(this.state.event___props['event_description'] && this.state.event___props['event_description'].length > 0) ? <>
									<ComTextComponent text={ this.state.event___props['event_description'] }/>
									<br />
								</> : <></>}

								<ComButtonShareComponent element_type="event" element={ this.state.event___props } is_logged={ this.props.is_logged } is_logged___props={ this.props.is_logged___props }/>

								{(this.state.event___poi___props !== null) ? <>
									<section style={{marginBottom:'10pt'}}>
										<Link to={ '/view/poi/' + this.state.event___poi___props['poi_id'] }>
											<ComPoiComponent poi_props={ this.state.event___poi___props } size="normal"/>
										</Link>
									</section>
								</> : <>
									<ComMapPreviewAddressComponent coords={{ lng: this.state.event___props['event_coords_lng'], lat: this.state.event___props['event_coords_lat'] }} canOpenRoute={ true }/>
								</>}

								{(this.state.event___props['event_user_public'] && this.state.event___props['event_user_public'] === true && this.state.event___props['event_user'] !== null) ? <>
									<br />
									<br />
									<br />
									<p className="input---nb-label" style={{textAlign:'center'}}>{ this._LOCALE.translate(locale___SbEventViewComponent.event_user_label)}</p>
									{(this.state.event___user___props !== null) ? <>
										{(this.props.is_logged && this.props.is_logged === true) ? <>
											<Link to={ '/view/user/' + this.state.event___props['event_user']['user_username'] }>
												<ComUserComponent user___props={ this.state.event___user___props } is_logged={ this.props.is_logged } is_logged___props={ this.props.is_logged___props } />
											</Link>
										</> : <>
											<ComUserComponent user___props={ this.state.event___user___props } is_logged={ this.props.is_logged } is_logged___props={ this.props.is_logged___props } />
										</>}
									</> : <></>}
								</> : <></>}

								<br />
								<br />
								<br />

								<ComEventDisclaimerViewComponent/>

							</div>
						</> : <>
							<Com404Component/>
						</>}
					</>}
				</IonContent>

			</IonPage>
		</>;
	}

//#endregion

}

//	E X P O R T 

export default withIonLifeCycle(SbEventsViewPage);