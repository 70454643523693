import React from 'react';
import { IonBackButton, IonButtons, IonCard, IonContent, IonHeader, IonItem, IonLabel, IonList, IonListHeader, IonPage, IonToolbar, withIonLifeCycle } from '@ionic/react';

//	L O C A L I Z A T I O N

import { locale___App } from '../../../App.locale';
import { locale___SbSettingsDatasCachePage } from './sb-settings-datas-cache.locale';

//	S T Y L E

import './sb-settings-datas-cache.scss';

//	S T A T E

import { state___SbSettingsDatasCachePage, state___SbSettingsDatasCachePage___default } from './sb-settings-datas-cache.state';

//	S E R V I C E S

import { service_LocalizationService } from '../../../services/service-localization';
import { service_RestAuthLogoutService } from '../../../services/service-auth-logout';

//	C O M P O N E N T S

import ComSpinnerComponent from '../../../components/com-spinner/com-spinner';

//	C L A S S

class SbSettingsDatasCachePage extends React.Component<{}, state___SbSettingsDatasCachePage>
{

//#region 																							D E C L A R A T I O N S
	
	private readonly _LOCALE: service_LocalizationService = new service_LocalizationService();
	private readonly _AUTH_LOGOUT: service_RestAuthLogoutService = new service_RestAuthLogoutService();

//#endregion

//#region 																							C O N S T R U C T O R

	constructor(
		public props: {},
	) {
		super(props);
		this.state = state___SbSettingsDatasCachePage___default;
	}

//#endregion

//#region 																							H A N D L E R S

	private handle___localStorage___clear = () => {
		this.setState({
			localStorage___is_clearing: true,
		}, async () => {
			await this._AUTH_LOGOUT.perform___logout();
			setTimeout(() => {
				localStorage.clear();
				window.location.href = '/';
			}, 1500);
		});
	}

//#endregion

//#region 																							R E N D E R

	render() : React.ReactNode
	{
		return <>
			<IonPage>

				<IonHeader className="ion-no-border" collapse="fade">
					<IonToolbar>
						<IonButtons slot="start">
							<IonBackButton defaultHref="/settings" text={ this._LOCALE.translate(locale___App.element___IonBackButton) }></IonBackButton>
						</IonButtons>
					</IonToolbar>
				</IonHeader>

				<IonContent forceOverscroll={ false }>

					{(this.state.localStorage___is_clearing === true) ? <>
					
						<section className="sb-settings-datas-cache---container">
							<br />
							<br />
							<br />
							<br />
							<br />
							<br />
							<br />
							<br />
							<br />
							<ComSpinnerComponent size="small"/>
							<br />
							<br />
							<p>{ this._LOCALE.translate(locale___SbSettingsDatasCachePage.is_deleting) }</p>
						</section>

					</> : <>

						<br />
					
						<section className="sb-settings-datas-cache---container">
							<img src="../../assets/ui/icons/icon-cookies.svg"/>
						</section>
						
						<br />
						<br />

						<IonListHeader>
							<IonLabel style={{textAlign:'center'}}>{ this._LOCALE.translate(locale___SbSettingsDatasCachePage.title) }</IonLabel>
						</IonListHeader>

						<br/>
					
						<section className="sb-settings-datas-cache---container">

							<p>{ this._LOCALE.translate(locale___SbSettingsDatasCachePage.description) }</p>
							
							<br />
							<br />
							<br />

							<IonCard color="danger" onClick={() => { this.handle___localStorage___clear(); }}>
								<IonList color="danger">
									<IonItem color="danger">
										<IonLabel style={{textAlign:'center',fontWeight:'bold'}}>
											<b>{ this._LOCALE.translate(locale___SbSettingsDatasCachePage.button___delete) }</b>
										</IonLabel>
									</IonItem>
								</IonList>
							</IonCard>

							<br/>
							<br/>

						</section>
					
					
					</>}

				</IonContent>

			</IonPage>
		</>;
	}

//#endregion

}

//	E X P O R T

export default withIonLifeCycle(SbSettingsDatasCachePage);