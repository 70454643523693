import React from 'react';
import { IonActionSheet, IonBackButton, IonButtons, IonCard, IonContent, IonHeader, IonLabel, RefresherEventDetail, IonListHeader, IonPage, IonRefresher, IonToolbar, withIonLifeCycle, IonList, IonItem } from '@ionic/react';
import { Link } from 'react-router-dom';

//	S T Y L E

import './sb-archive-poi.scss';

//	I N T E R F A C E S

import { interface___IonActionSheet } from '../../../interfaces/interface.actionsheet';

//	S T A T E   -   P R O P S

import { props___SbArchivePoiPage, state___SbArchivePoiPage, state___SbArchivePoiPage___default } from './sb-archive-poi.state';

//	T Y P E S

import { type___api___response } from '../../../types/types.api-response';

//	S E R V I C E S

import { service_RestApiService } from '../../../services/service-api';

//	C O M P O N E N T S

import ComInfiniteScrollerComponent from '../../../components/com-infinite-scroller/com-infinite-scroller';
import ComListEmptyComponent from '../../../components/com-list-empty/com-list-empty';
import ComPoiComponent from '../../../components/com-poi/com-poi';
import ComSpinnerInlineComponent from '../../../components/com-spinner-inline/com-spinner-inline';
import ComListSeparatorComponent from '../../../components/com-list-separator/com-list-separator';

//	M O D A L S

import MdPoiPaymentModal from '../../../modals/md-poi-payment/md-poi-payment';

//	C L A S S

class SbArchivePoiPage extends React.Component<props___SbArchivePoiPage, state___SbArchivePoiPage>
{

//#region 																							D E C L A R A T I O N S

	private readonly _API: service_RestApiService = new service_RestApiService();

//#endregion

//#region 																							C O N S T R U C T O R

	constructor(
		public props: props___SbArchivePoiPage,
	) {
		super(props);
		this.state = state___SbArchivePoiPage___default;
	}

//#endregion

//#region 																							R E A D   P R O P S

	private read_props___poi_collection = async () => {
		const ___temp___poi_collection: type___api___response = await this._API.poi___read___multi('mine', '', this.state.poi___collection.length);
		this.setState((prevState) => ({
			poi___collection: [...prevState.poi___collection, ...___temp___poi_collection.data],
			poi___collection___is_loading: false,
			poi___collection___is_complete: (___temp___poi_collection.response === 'no-data') ? true : false,
		}));
	}






	










//#endregion

//#region 																							H A N D L E R S

	private handle___page___refresh = (___e: CustomEvent<RefresherEventDetail>) => {
		this.setState({
			poi___collection: [],
			poi___collection___is_loading: true,
		}, () => {
			___e.detail.complete();
		});
	}

	private handle___page___load_more = () => {
		this.setState({
			poi___collection___is_loading: true
		}, async () => {
			await this.read_props___poi_collection();
		});
	}

	private handle___poi_new___payment = () => {

		//	<Link to="/edit/poi/new">

		this.setState({
			ionModal___MdPoiPaymentModal___isOpen: true,
		});
	}

	private handle___poi_new___askCreate = () => {








	}

//#endregion

//#region 																							L I F E C Y C L E

	async ionViewDidEnter() : Promise<void> {
		await this.read_props___poi_collection();
	}

//#endregion

//#region 																							R E T U R N

	render() : React.ReactNode
	{
		return <>
			<IonPage>

				{/**
					<MdPoiPaymentModal isOpen={ this.state.ionModal___MdPoiPaymentModal___isOpen } event___onDidDismiss={ this.handle___poi_new___create }/>
				*/}

				<IonHeader>
					<IonToolbar>
						<IonButtons slot="start">
							<IonBackButton defaultHref="/discover" />
						</IonButtons>
					</IonToolbar>
				</IonHeader>

				<IonContent forceOverscroll={ false }>

					<IonRefresher slot="fixed" onIonRefresh={ this.handle___page___refresh }>
						<ComSpinnerInlineComponent/>
					</IonRefresher>

					<section className="sb-user-portfolio---container">

						<IonListHeader>
							<IonLabel>
								<i className="fas fa-list"/>
								<span>I tuoi Punti di Interesse</span>
							</IonLabel>
						</IonListHeader>

						<br />

						{/*
							{(this.props.is_logged___props['user_permission___poi'] && this.props.is_logged___props['user_permission___poi'] === true) ? <>
								<Link to="/edit/poi/new">
									<IonCard>
										<IonList>
											<IonItem className="is-ion-item-button" button={ true } onClick={ this.handle___poi_new___payment }>
												<i className="fas fa-plus"></i>
												<IonLabel>Crea punto di interesse</IonLabel>
											</IonItem>
										</IonList>
									</IonCard>
								</Link>
								<br />
								<br />
							</> : <></>}
						*/}

						{this.state.poi___collection.map((___p: any, ___p___idx: number) => {
							return <>
								<Link key={ ___p___idx } to={ '/view/poi/' + ___p['poi_id'] }>
									<ComPoiComponent poi_props={ ___p } size="normal" />
								</Link>
								<ComListSeparatorComponent/>
							</>;
						})}

						{(this.state.poi___collection___is_complete === true) ? <>
							<ComListEmptyComponent list_items_quantity={ this.state.poi___collection.length }/>
						</> : <>
							<ComInfiniteScrollerComponent is_loading={ this.state.poi___collection___is_loading } event___scrollTriggered={ this.handle___page___load_more } />
						</>}

						<br />
						<br />

					</section>

				</IonContent>

			</IonPage>
		</>;
	}

//#endregion

}

//	E X P O R T

export default withIonLifeCycle(SbArchivePoiPage);