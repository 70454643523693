import React from 'react';
import { IonBackButton, IonButtons, IonCard, IonContent, IonHeader, IonItem, IonLabel, IonList, IonListHeader, IonPage, IonRefresher, IonToolbar, RefresherEventDetail, withIonLifeCycle } from '@ionic/react';

//	S T Y L E

import './sb-poi-edit-roles.scss';

//	S T A T E   -   P R O P S

import { props___SbPoiEditRolesPage, state___SbPoiEditRolesPage, state___SbPoiEditRolesPage___default } from './sb-poi-edit-roles.state';

//	T Y P E S

import { type___api___response } from '../../../types/types.api-response';

//	S E R V I C E S

import { service_RestApiService } from '../../../services/service-api';
import { service_Router } from '../../../services/service-router';

//	C O M P O N E N T S

import ComPoiComponent from '../../../components/com-poi/com-poi';
import ComListSeparatorComponent from '../../../components/com-list-separator/com-list-separator';
import ComSpinnerInlineComponent from '../../../components/com-spinner-inline/com-spinner-inline';
import ComUserComponent from '../../../components/com-user/com-user';

//	M O D A L S

import MdPoiRoleAddModal from '../../../modals/md-poi-role-add/md-poi-role-add';

//	C L A S S

class SbPoiEditRolesPage extends React.Component<props___SbPoiEditRolesPage, state___SbPoiEditRolesPage>
{

//#region 																							D E C L A R A T I O N S

	private readonly _API: service_RestApiService = new service_RestApiService();
	private readonly _ROUTER: service_Router = new service_Router();

//#endregion

//#region 																							C O N S T R U C T O R

	constructor(
		public props: props___SbPoiEditRolesPage,
	) {
		super(props);
		this.state = state___SbPoiEditRolesPage___default;
	}

//#endregion

//#region 																							R E A D   P R O P S

	private read_props___poi = async () => {
		const ___temp___poi___props: type___api___response = await this._API.poi___read___single(this.state.poi___id);
		this.setState({
			poi___props: ___temp___poi___props.data,
			poi___props___is_loading: false,
		});
	}

	private read_props___users___collection = async () => {
		const ___temp___user___collection___creator_events: type___api___response = await this._API.users___read___multi('poi_permissions___events', this.state.poi___id);
		this.setState({
			user___collection___creator_events: ___temp___user___collection___creator_events.data,
			user___collection___is_loading: false,
		});
	}

//#endregion

//#region 																							H A N D L E R S

	private handle___page_refresh = (___e: CustomEvent<RefresherEventDetail>) => {
		this.read_props___users___collection();
		___e.detail.complete();
	}

	private handle___user___options = async (___event___id: string) => {
		this.setState({
			actionSheet___user_options___target: ___event___id,
			actionSheet___user_options: true
		}); 
	}

	private handle___role_added = () => {
		this.setState({
			ionModal___MdPoiRoleAddModal___isOpen: false,
			ionModal___MdPoiRoleAddModal___argument: null,
		}, async () => {
			await this.read_props___users___collection();
		});
	}

//#endregion

//#region 																							L I F E C Y C L E

	async ionViewWillEnter() : Promise<void> {
		const ___poi_id: string | null = this._ROUTER.get_id(this.props);
		if (___poi_id) {
			this.setState({
				poi___id: ___poi_id,
			}, async () => {
				await this.read_props___poi();
				await this.read_props___users___collection();
			});
		}
	}

//#endregion

//#region 																							R E T U R N

	render() : React.ReactNode
	{
		return <>
			<IonPage>

				{(this.state.poi___props !== null) ? <>
					<MdPoiRoleAddModal isOpen={ this.state.ionModal___MdPoiRoleAddModal___isOpen } poi={ this.state.poi___props } user_role={ this.state.ionModal___MdPoiRoleAddModal___argument } event___onDidDismiss={ this.handle___role_added } is_logged={ this.props.is_logged } is_logged___props={ this.props.is_logged___props } />
				</> : <></>}

				<IonHeader className="ion-no-border" collapse="fade">
					<IonToolbar>
						<IonButtons slot="start">
							<IonBackButton defaultHref="/dashboard"></IonBackButton>
						</IonButtons>
					</IonToolbar>
				</IonHeader>

				<IonContent>

					<IonRefresher slot="fixed" onIonRefresh={ this.handle___page_refresh }>
						<ComSpinnerInlineComponent/>
					</IonRefresher>

					<section className="sb-users-list---container">
						{(this.state.poi___props___is_loading === true || this.state.user___collection___is_loading === true) ? <>

							<br />
							<br />
							<br />
							<ComSpinnerInlineComponent/>

						</> : <>

							<IonListHeader>
								<IonLabel>Ruoli degli utenti per:</IonLabel>
							</IonListHeader>

							<br />

							<ComPoiComponent poi_props={ this.state.poi___props } size="normal" />

							<br />

							<IonListHeader>
								<IonLabel>
									<i className="fas fa-calendar-plus"></i>
									<span>Creatori di eventi</span>
									</IonLabel>
							</IonListHeader>

							<p>Questi utenti hanno la possibilità di creare e gestire gli eventi di questo Punto di Interesse</p>

							<IonCard>
								<IonList>
									<IonItem button={ true } onClick={() => { this.setState({ ionModal___MdPoiRoleAddModal___isOpen: true, ionModal___MdPoiRoleAddModal___argument: 'poi_permissions___events' }); }}>
										<IonLabel>aggiungi utente</IonLabel>
									</IonItem>
								</IonList>
							</IonCard>

							<br />

							{this.state.user___collection___creator_events.map((___ucce___user: any, ___ucce___user___idx: number) => {
								return <>
									<section key={ ___ucce___user___idx } onClick={() => { }}>
										<ComUserComponent user___props={ ___ucce___user } is_logged={ this.props.is_logged } is_logged___props={ this.props.is_logged___props } />
									</section>
									<ComListSeparatorComponent />
								</>;
							})}

							<br />


























						</>}
					</section>

				</IonContent>

			</IonPage>
		</>;
	}

//#endregion

}

//	E X P O R T

export default withIonLifeCycle(SbPoiEditRolesPage);