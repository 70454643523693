import { type___locale___translations___collection } from '../../types/types.locale';

//	E X P O R T   L O C A L E

export const locale___ComUserComponent: type___locale___translations___collection = {
	'followed_by_both': {
		de_de: 'ihr folgt einander',
		en_us: 'you follow each other',
		es_es: 'ustedes se siguen',
		fr_fr: 'vous vous suivez',
		it_it: 'vi seguite a vicenda',
	},
	'followed_by_you': {
		de_de: 'du folgst ihm bereits',
		en_us: 'you already follow him',
		es_es: 'ya lo sigues',
		fr_fr: 'tu le suis déjà',
		it_it: 'lo segui già',
	},
	'followed_by_him': {
		de_de: 'folgt dir',
		en_us: 'follows you',
		es_es: 'te sigue',
		fr_fr: 'te suit',
		it_it: 'ti segue',
	},
	'follows_total': {
		de_de: '{{1}} kontakte',
		en_us: '{{1}} contacts',
		es_es: '{{1}} contactos',
		fr_fr: '{{1}} contacts',
		it_it: '{{1}} contatti',
	},
	'follows_nothing': {
		de_de: 'kein kontakt',
		en_us: 'no contact',
		es_es: 'sin contacto',
		fr_fr: 'aucun contact',
		it_it: 'nessun contatto',
	},
};