import React from 'react';
import { IonBackButton, IonButtons, IonContent, IonHeader, IonPage, IonToolbar, withIonLifeCycle } from '@ionic/react';

//	L O C A L I Z A T I O N

import { locale___App } from '../../../App.locale';

//	S T Y L E

import './sb-info-eula.scss';

//	S E R V I C E S

import { service_LocalizationService } from '../../../services/service-localization';

//	C O M P O N E N T S

import ComMarkdownComponent from '../../../components/com-markdown/com-markdown';

//	C L A S S

class SbInfoEulaPage extends React.Component<{}, {}>
{

//#region 																							D E C L A R A T I O N S
	
	private readonly _LOCALE: service_LocalizationService = new service_LocalizationService();

//#endregion

//#region 																							R E N D E R

	render() : React.ReactNode
	{
		return <>
			<IonPage>

				<IonHeader className="ion-no-border" collapse="fade">
					<IonToolbar>
						<IonButtons slot="start">
							<IonBackButton defaultHref="/settings" text={ this._LOCALE.translate(locale___App.element___IonBackButton) } />
						</IonButtons>
					</IonToolbar>
				</IonHeader>

				<IonContent forceOverscroll={ false }>
					<section className="sb-info-eula---container">
					<ComMarkdownComponent file_path={ '/assets/docs/eula/eula.' + 'it_it' + '.md' }/>
					</section>
				</IonContent>

			</IonPage>
		</>;
	}

//#endregion

}

//	E X P O R T

export default withIonLifeCycle(SbInfoEulaPage);