import React from 'react';
import { IonAlert, IonButton, IonButtons, IonCard, IonContent, IonFooter, IonHeader, IonItem, IonLabel, IonList, IonModal, IonToast, IonToolbar } from '@ionic/react';
import { Camera } from "react-camera-pro";

//	S T Y L E

import './md-tales-create.scss';

//	I N T E R F A C E S

import { interface___IonAlert } from '../../interfaces/interface.alert';

//	L I B S

import { lib_names } from '../../libs/lib.names';
import { lib_names_shared } from '../../libs/lib.names.shared';

//	F U N C S

import { funcs_text } from '../../funcs/funcs.text';

//	S T A T E   -   P R O P S

import { props___MdTalesCreateModal, state___MdTalesCreateModal, state___MdTalesCreateModal___default } from './md-tales-create.state';

//	T Y P E S

import { type___api___response } from '../../types/types.api-response';
import { type___geo___lngLat, type___map___position_response } from '../../types/types.types';
import { type___modal___config } from '../../types/types.modals';

//	S E R V I C E S

import { service_GeolocationService } from '../../services/service-geolocation';
import { service_RestApiService } from '../../services/service-api';

//	C O M P O N E N T S

import ComCameraUnavailableComponent from '../../components/com-camera-unavailable/com-camera-unavailable';
import ComInputTextareaComponent from '../../components/com-input-textarea/com-input-textarea';
import ComListEmptyComponent from '../../components/com-list-empty/com-list-empty';
import ComMapPreviewComponent from '../../components/com-map-preview/com-map-preview';
import ComPoiComponent from '../../components/com-poi/com-poi';
import ComSpinnerInlineComponent from '../../components/com-spinner-inline/com-spinner-inline';
import ComSpinnerComponent from '../../components/com-spinner/com-spinner';

//	M O D A L S

import MdMediaEditorModal from '../md-media-editor/md-media-editor';
import MdPoiSelectModal from '../md-poi-select/md-poi-select';
import MdPositionSelectorModal from '../md-position-selector/md-position-selector';

//	C L A S S

export default class MdTalesCreateModal extends React.Component<props___MdTalesCreateModal, state___MdTalesCreateModal>
{

//#region 																							D E C L A R A T I O N S

	private readonly _API: service_RestApiService = new service_RestApiService();
	private readonly _GEOLOCATION: service_GeolocationService = new service_GeolocationService();

	private readonly funcs___text: funcs_text = new funcs_text();

	private camera___mediaStream: MediaStream;
	private camera___Ref: React.RefObject<any|null>;

//#endregion

//#region 																							C O N F I G

	private readonly MdTalesCreateModal___modal_config: type___modal___config = {
		onDidPresent: () => { this.event___onDidPresent(); },
		onDidDismiss: () => { this.event___onDidDismiss(); },
	};

	private readonly ionAlert___askForClose___config: interface___IonAlert = {
		header: 'Attenzione',
		message: 'Vuoi eliminare le modifiche a questa Tale?',
		buttons: [
			{
				text: 'Si',
				handler: () => {
					this.handle___MdTalesCreateModal___close();
				}
			},
			{
				text: 'Annulla',
				role: 'cancel',
				handler: () => {
					this.setState({ ionAlert___askForClose___isOpen: false });
				}
			}
		]
	};

//#endregion

//#region 																							C O N S T R U C T O R

	constructor(
		public props: props___MdTalesCreateModal,
	) {
		super(props);
		this.state = state___MdTalesCreateModal___default;
		this.camera___Ref = React.createRef();
	}

//#endregion

//#region 																							C O N S T R U C T O R

	private camera___check_is_granted_access = async () => {
		const ___camera_is_available: boolean = await (async () => {
			try {
				this.camera___mediaStream = await navigator.mediaDevices.getUserMedia({ video: true });
				return true;
			} catch (___error: any) {
				console.log('Error using camera : ', ___error)
				return false;
			}
		})();
		this.setState({
			camera_env___is_available: ___camera_is_available
		});
	}

//#endregion

//#region 																							H A N D L E R S

	private handle___camera___takePhoto = () => {
		const photo = this.camera___Ref.current.takePhoto();
		this.setState({
			tale___media: photo
		}, async () => {
			await this.handle___geolocate_tale();
		});
	};

	private handle___camera___switchEnv = () => {
		this.camera___Ref.current.switchCamera();
	}

	private handle___MdTalesCreateModal___askClose = () => {
		this.setState({
			ionAlert___askForClose___isOpen: true
		});
	}

	private handle___MdTalesCreateModal___close = () => {
		this.setState(state___MdTalesCreateModal___default, () => {
			this.props.event___onDidDismiss();
		});
	}

	private handle___gallery___taleFileUpload = (___e: React.ChangeEvent<HTMLInputElement>) => {
		if (___e.target.files) {
			const ___files_array: File[] = Array.from(___e.target.files);
			const ___f: File = ___files_array[0];
			const ___f___reader: FileReader = new FileReader();
			___f___reader.onloadend = () => {
				this.setState({
					tale___media: ___f___reader.result as string,
					ionModal___MdMediaEditorModal___isOpen: true
				});
			};
			___f___reader.readAsDataURL(___f);
		}
	}

	private handle___gallery___taleFileEditEnd = (___retrieved_gallery: string[] | null) => {
		this.setState({
			tale___media: (___retrieved_gallery && ___retrieved_gallery.length > 0) ? ___retrieved_gallery[0] : this.state.tale___media,
			ionModal___MdMediaEditorModal___isOpen: false,
		}, async () => {
			await this.handle___geolocate_tale();
		});
	}

	private handle___geolocate_tale = async () => {
		const ___me___geolocation: type___geo___lngLat | null = await this._GEOLOCATION.geolocation___locate();
		this.setState({
			tale___position___coords: ___me___geolocation
		});
	}

	private handle___tale_position___select = (___poi_position: type___map___position_response) => {
		this.setState({
			ionModal___MdPositionSelectorModal___isOpen: false,
			tale___position___coords: ___poi_position.coords,
		});
	}

	private handle___tale_position___select___poi = (___chosen_poi: any | undefined | null) => {
		this.setState({
			ionModal___MdPoiSelectModal___isOpen: false,
			tale___position___poi: (___chosen_poi) ? ___chosen_poi : null,
		}, () => {
			if (this.state.tale___position___poi) {
				this.setState({
					tale___position___coords: {
						lng: this.state.tale___position___poi['poi_coords_lng'],
						lat: this.state.tale___position___poi['poi_coords_lat']
					}
				});
			}
		});
	}

//#endregion

//#region 																							S U B M I T

	private submit___tale = () => {
		this.setState({
			tale___is_submitting: true,
		}, async () => {
			const ___tale___is_submitted___poi_id: string | null = (this.state.tale___position___poi && this.state.tale___position___poi['poi_id']) ? this.state.tale___position___poi['poi_id'] : null;
			const ___tale___is_submitted: type___api___response = await this._API.tales___submit(this.state.tale___media, this.state.tale___text, this.state.tale___position___coords, this.state.tale___position___isPrivate, ___tale___is_submitted___poi_id);
			if (___tale___is_submitted && ___tale___is_submitted.response === 'success')
			{
				this.event___onDidDismiss();
				this.props.event___onDidDismiss();
			} else {
				this.setState({
					ionToast___error___isOpen: true,
				});
			}
		});
	}

//#endregion

//#region 																							L I F E C Y C L E

	private async event___onDidPresent() : Promise<void> {
		await this.camera___check_is_granted_access();
		await this.handle___geolocate_tale();
	}

	private event___onDidDismiss() : void {
		this.setState({
			...state___MdTalesCreateModal___default
		}, () => {
			const ___camera___mediaStream___tracks: MediaStreamTrack[] = this.camera___mediaStream.getTracks();
			___camera___mediaStream___tracks.forEach((___track: MediaStreamTrack) => { ___track.stop() });
		});
	}

//#endregion

//#region 																							R E T U R N

	render() : React.ReactNode
	{
		return <>
			<IonModal isOpen={ this.props.isOpen } { ...this.MdTalesCreateModal___modal_config }>

				<IonAlert isOpen={ this.state.ionAlert___askForClose___isOpen } {...this.ionAlert___askForClose___config}></IonAlert>
				<IonToast isOpen={ this.state.ionToast___error___isOpen} message="Errore nella pubblicazione della tale, riprova...." onDidDismiss={() => { this.setState({ ionToast___error___isOpen: false }); }} position="top" />

				<MdPositionSelectorModal isOpen={ this.state.ionModal___MdPositionSelectorModal___isOpen } position___input={ this.state.tale___position___coords } event___onDidDismiss={ this.handle___tale_position___select }/>
				<MdPoiSelectModal isOpen={ this.state.ionModal___MdPoiSelectModal___isOpen } target="search" event___onDidDismiss={ this.handle___tale_position___select___poi } />

				{(this.state.tale___media !== null) ? <>
					<MdMediaEditorModal isOpen={ this.state.ionModal___MdMediaEditorModal___isOpen } input___aspect_ratio={ lib_names.aspectRatios.tales.ar } input___image_list={[ this.state.tale___media ]} event___onDidDismiss={ this.handle___gallery___taleFileEditEnd } />
				</> : <></>}

				<IonContent forceOverscroll={ false } scrollX={ false } scrollY={ true }>
					{(this.state.camera_env___is_available === true) ? <>
					
						{(this.state.tale___media === null) ? <>

							<div className="md-tale-create---camera">
								<div>
									<Camera ref={ this.camera___Ref } errorMessages={{}} aspectRatio={ lib_names.aspectRatios.tales.ar }/>
								</div>
							</div>
							
							<div className="md-tale-create---camera---controls">
								<div>
									<div onClick={ this.handle___MdTalesCreateModal___close }><i className="fas fa-times"></i></div>
									<div onClick={ this.handle___camera___takePhoto }><i className="fas fa-circle"></i></div>
									<div onClick={ this.handle___camera___switchEnv }><i className="fas fa-sync-alt"></i></div>
								</div>
								<div>
									<div className="md-tale-create---camera---selected-mode">
										<i className="fas fa-camera"></i>
										<span>Scatta Foto</span>
									</div>
									<label htmlFor="field---tale---gallery-file-selector">
										<i className="far fa-images"></i>
										<span>Galleria</span>
										<input type="file" id="field---tale---gallery-file-selector" onChange={ this.handle___gallery___taleFileUpload } accept={ lib_names_shared.files.input_accepted } multiple style={{display:'none'}}/>
									</label>
								</div>
							</div>

						</> : <>
							{(this.state.tale___is_submitting === true) ? <>
							
								<br />
								<br />
								<br />
								<ComSpinnerComponent size="small" />
			
							</> : <>

								<IonHeader>
									<IonToolbar>
										<IonButtons slot="end">
											<IonButton onClick={ this.handle___MdTalesCreateModal___askClose }>
												<i className="fas fa-times"></i>
											</IonButton>
										</IonButtons>
									</IonToolbar>
								</IonHeader>

								<div className="md-tale-create---form">
									
									<section className="md-tale-create---form---preview">
										<img src={ this.state.tale___media } />
									</section>
									
									<section className="md-tale-create---form---writed">
										<textarea className="input---nb-outlined" placeholder="Scrivi qualcosa..." value={ this.state.tale___text } onInput={(___e: any) => { this.setState({ tale___text: this.funcs___text.text_input___process(___e.target.value) })}}></textarea>
										<ComInputTextareaComponent text={ this.state.tale___text } event___onInput={(___e: string) => { this.setState({ tale___text: ___e }); }} is_logged={ this.props.is_logged } is_logged___props={ this.props.is_logged___props } />
									</section>

									{(this.state.tale___position___poi !== false && this.state.tale___position___coords !== null) ? <>
										{(this.state.tale___position___coords !== null) ? <>
											<section onClick={() => { this.setState({ ionModal___MdPositionSelectorModal___isOpen: true }); }}>
												<ComMapPreviewComponent map_coords={{ lng: this.state.tale___position___coords['lng'], lat: this.state.tale___position___coords['lat'] }} canOpenRoute={ false }/>
											</section>
										</> : <>
											<ComListEmptyComponent text="impossibile identificare la tua posizione..."/>
										</>}
										<section className="md-tale-create---form---position">
											{(this.state.tale___position___poi !== null) ? <>
												<div onClick={() => { this.setState({ ionModal___MdPoiSelectModal___isOpen: true }); }}>
													<ComPoiComponent poi_props={ this.state.tale___position___poi } size="small" />
													<p className="input---nb-label" style={{textAlign:'center'}}>cambia poi abbinato</p>
												</div>
											</> : <>
												<IonCard>
													<IonList>
														<IonItem className="is-ion-item-button" button={ true } onClick={() => { this.setState({ ionModal___MdPoiSelectModal___isOpen: true }); }}>
															<i className="fas fa-crown"></i>
															<IonLabel>Abbina ad un Poi</IonLabel>
														</IonItem>
													</IonList>
												</IonCard>
											</>}
										</section>
									</> : <>
										<br />
										<br />
										<br />
										<br />
										<ComSpinnerInlineComponent/>
									</>}

								</div>
							
							</>}
						</>}
						
					</> : <>

						<ComCameraUnavailableComponent event___onDidRetry={ this.camera___check_is_granted_access } event___onDidCancel={ this.props.event___onDidDismiss }/>
					
					</>}

				</IonContent>

				{(this.state.tale___is_submitting === false) ? <>
					{(this.state.tale___media !== null) ? <>
						<IonFooter>
							{(this.state.tale___position___poi !== null || this.state.tale___position___coords !== null) ? <>
								<section className="container---footer-section">
									<button className="is-element is-button is-color---folly is-full-width" onClick={ this.submit___tale }>
										<i className="fa-solid fa-check"></i>
										<h4>Pubblica</h4>
									</button>
								</section>
							</> : <></>}
						</IonFooter>
					</> : <></>}
				</> : <></>}

			</IonModal>
		</>;
	}

//#endregion

}