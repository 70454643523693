import React from 'react';
import { IonButton, IonButtons, IonContent, IonHeader, IonModal, IonToolbar } from '@ionic/react';

//	L O C A L I Z A T I O N

//	S T Y L E

import './md-info-eula.scss';

//	S T A T E   -   P R O P S

import { props___MdInfoEulaModal } from './md-info-eula.state';

//	T Y P E S

import { type___modal___config } from '../../types/types.modals';

//	S E R V I C E S

import { service_LocalizationService } from '../../services/service-localization';

//	C O M P O N E N T S

import ComMarkdownComponent from '../../components/com-markdown/com-markdown';

//	C L A S S

export default class MdInfoEulaModal extends React.Component<props___MdInfoEulaModal, {}>
{

//#region 																							C O N F I G

	private readonly MdInfoEulaModal___modal_config: type___modal___config = {
		breakpoints: [ 1 ],
		backdropDismiss: true,
		handle: true,
		initialBreakpoint: 1,
		keyboardClose: true,
		showBackdrop: true,
//		onDidPresent: () => { this.event___onDidPresent(); },
//		onDidDismiss: () => { this.event___onDidDismiss(); }
	};

//#endregion

//#region 																							D E C L A R A T I O N S

	private readonly _LOCALE: service_LocalizationService = new service_LocalizationService();

//#endregion

//#region 																							R E T U R N

	render() : React.ReactNode
	{
		return <>
			<IonModal isOpen={ this.props.isOpen } {...this.MdInfoEulaModal___modal_config}>

				<IonHeader>
					<IonToolbar>
						<IonButtons slot="end">
							<IonButton onClick={ this.props.event___onDidDismiss }>
								<b>chiudi</b>
							</IonButton>
						</IonButtons>
					</IonToolbar>
				</IonHeader>
					
				<IonContent forceOverscroll={ false }>
					<section className="sb-info-eula---container">
						<ComMarkdownComponent file_path={ '/assets/docs/eula/eula.' + 'it_it' + '.md' }/>
					</section>
				</IonContent>

			</IonModal>
		</>;
	}

//#endregion

}