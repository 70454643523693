import React from 'react';
import { IonButton, IonButtons, IonContent, IonHeader, IonLabel, IonListHeader, IonModal, IonToolbar } from '@ionic/react';
import { Link } from 'react-router-dom';

//	L O C A L I Z A T I O N

import { locale___MdDUserFollowsModal } from './md-user-follows.locale';

//	T Y P E S

import { type___api___response } from '../../types/types.api-response';
import { type___modal___config } from '../../types/types.modals';

//	S T Y L E

import './md-user-follows.scss';

//	S T A T E   -   P R O P S

import { props___MdUserFollowsModal, state___MdUserFollowsModal, state___MdUserFollowsModal___default } from './md-user-follows.state';

//	S E R V I C E S

import { service_RestApiService } from '../../services/service-api';
import { service_LocalizationService } from '../../services/service-localization';

//	C O M P O N E N T S

import ComInfiniteScrollerComponent from '../../components/com-infinite-scroller/com-infinite-scroller';
import ComListEmptyComponent from '../../components/com-list-empty/com-list-empty';
import ComListSeparatorComponent from '../../components/com-list-separator/com-list-separator';
import ComUserComponent from '../../components/com-user/com-user';

//	C L A S S

export default class MdUserFollowsModal extends React.Component<props___MdUserFollowsModal, state___MdUserFollowsModal>
{

//#region 																							C O N F I G

	private readonly MdUserFollowsModal___modal_config: type___modal___config = {
		breakpoints: [ 1 ],
		backdropDismiss: true,
		handle: true,
		initialBreakpoint: 1,
		keyboardClose: true,
		showBackdrop: true,
		onDidPresent: () => { this.event___onDidPresent(); },
		onDidDismiss: () => { this.event___onDidDismiss(); }
	};

//#endregion

//#region 																							D E C L A R A T I O N S

	private readonly _LOCALE: service_LocalizationService = new service_LocalizationService();
	private readonly _API: service_RestApiService = new service_RestApiService();

//#endregion

//#region 																							C O N S T R U C T O R

	constructor(
		public props: props___MdUserFollowsModal,
	)
	{
		super(props);
		this.state = state___MdUserFollowsModal___default;
	}

//#endregion

//#region 																							R E A D   P R O P S

	private read_props___user_follows = async () => {
		const ___temp___poi_collection___props: type___api___response = await this._API.users___read___multi(this.props.target, this.props.target_user, this.state.user_follows___props.length);
		this.setState((prevState) => ({
			user_follows___props: [...prevState.user_follows___props, ...___temp___poi_collection___props.data],
			user_follows___props___is_loading: false,
			user_follows___props___is_complete: (___temp___poi_collection___props.response === 'success') ? true : false,
		}));
	}

//#endregion

//#region 																							L I F E C Y C L E

	private event___onDidPresent() : void
	{
		this.setState({
			...state___MdUserFollowsModal___default
		}, async () => {
			await this.read_props___user_follows();
		});
	}

	private event___onDidDismiss() : void
	{
		this.setState({
			...state___MdUserFollowsModal___default
		});
	}

//#endregion

//#region 																							R E N D E R

	render() : React.ReactNode
	{
		return <>
			<IonModal isOpen={ this.props.isOpen } {...this.MdUserFollowsModal___modal_config} handleBehavior="cycle">

				<IonHeader>
					<IonToolbar>
						<IonButtons slot="end">
							<IonButton onClick={ this.props.event___onDidDismiss }>
								<b>{ this._LOCALE.translate(locale___MdDUserFollowsModal.topBarButton___close) }</b>
							</IonButton>
						</IonButtons>
					</IonToolbar>
				</IonHeader>

				<IonContent forceOverscroll={ false }>
					<section className="md-user-follows---container">

						<IonListHeader>
							{(() => { switch (this.props.target) {
								case 'followers': return <>
									<IonLabel>{ this._LOCALE.translate(locale___MdDUserFollowsModal.title___followers) }</IonLabel>
								</>; break;
								case 'following': return <>
									<IonLabel>{ this._LOCALE.translate(locale___MdDUserFollowsModal.title___followings) }</IonLabel>
								</>; break;
							}})()}
						</IonListHeader>

						<br />
					
						{this.state.user_follows___props.map((___u_f: any, ___u_f___idx: number) => {
							return <>
								<Link key={ ___u_f___idx } to={ '/view/user/' + ___u_f['user_username'] } onClick={ this.props.event___onDidDismiss }>
									<ComUserComponent user___props={ ___u_f } is_logged={ this.props.is_logged } is_logged___props={ this.props.is_logged___props } />	
								</Link>
								<ComListSeparatorComponent />
							</>;
						})}

						{(this.state.user_follows___props___is_complete === true) ? <>
							<ComListEmptyComponent list_items_quantity={ this.state.user_follows___props.length }/>
						</> : <>
							<ComInfiniteScrollerComponent is_loading={ this.state.user_follows___props___is_loading } event___scrollTriggered={ this.read_props___user_follows } />
						</>}
						
					</section>
				</IonContent>

			</IonModal>
		</>;
	}

//#endregion

}