import React from 'react';
import { IonButton, IonButtons, IonContent, IonHeader, IonLabel, IonListHeader, IonModal, IonToolbar } from '@ionic/react';
import { debounce } from 'lodash';

//	T Y P E S

import { type___api___response } from '../../types/types.api-response';
import { type___modal___config } from '../../types/types.modals';

//	S T Y L E

import './md-user-search.scss';

//	S T A T E   -   P R O P S

import { props___MdUserSearchModal, state___MdUserSearchModal, state___MdUserSearchModal___default } from './md-user-search.state';

//	L I B S

import { lib_names } from '../../libs/lib.names';

//	S E R V I C E S

import { service_RestApiService } from '../../services/service-api';
import { service_LocalizationService } from '../../services/service-localization';

//	C O M P O N E N T S

import ComInfiniteScrollerComponent from '../../components/com-infinite-scroller/com-infinite-scroller';
import ComListEmptyComponent from '../../components/com-list-empty/com-list-empty';
import ComListSeparatorComponent from '../../components/com-list-separator/com-list-separator';
import ComUserComponent from '../../components/com-user/com-user';

//	C L A S S

export default class MdUserSearchModal extends React.Component<props___MdUserSearchModal, state___MdUserSearchModal>
{

//#region 																							C O N F I G

	private readonly MdUserSearchModal___modal_config: type___modal___config = {
		breakpoints: [ 1 ],
		backdropDismiss: true,
		handle: true,
		initialBreakpoint: 1,
		keyboardClose: true,
		showBackdrop: true,
		onDidPresent: () => { this.event___onDidPresent(); },
	};

//#endregion

//#region 																							D E C L A R A T I O N S

	private readonly _LOCALE: service_LocalizationService = new service_LocalizationService();
	private readonly _API: service_RestApiService = new service_RestApiService();

//#endregion

//#region 																							C O N S T R U C T O R

	constructor(
		public props: props___MdUserSearchModal,
	) {
	
		super(props);
		this.state = state___MdUserSearchModal___default;

		this.constructor___debounce();

	}

	constructor___debounce() : void {

		this.handle___discover___search___input = this.handle___discover___search___input.bind(this);
		this.handle___discover___search = debounce(this.handle___discover___search.bind(this), lib_names.lodash.debounce_delay);

	}

//#endregion

//#region 																							R E A D   P R O P S

	private read_props___search_results = async () => {
		const ___temp___search_results___props: type___api___response = await this._API.search___full('users_follows', this.state.users_search___param, this.state.users_search___results.length);
		this.setState((prevState) => ({
			users_search___results: [...prevState.users_search___results, ...___temp___search_results___props.data],
			users_search___results___is_loading: false,
			users_search___results___is_complete: (___temp___search_results___props.response === 'no-data') ? true : false,
		}));
	}

//#endregion

//#region 																							H A N D L E R S

	private handle___discover___search___input = (___search_param: string) => {
		this.setState({
			users_search___param: ___search_param,
			users_search___results___is_loading: true,
			users_search___results: [],
		}, () => {
			this.handle___discover___search(this.state.users_search___param);
		});
	}

	private handle___discover___search = (___search_param: string) => {
		this.setState({
			users_search___param: ___search_param,
			users_search___results: [],
			users_search___results___is_loading: true,
		}, async () => {
			if (this.state.users_search___param && this.state.users_search___param.length > 0) {
				await this.read_props___search_results();
				console.log('asd')
			}
		});
	}

//#endregion

//#region 																							L I F E C Y C L E

	private event___onDidPresent() : void
	{
		this.setState({
			...state___MdUserSearchModal___default
		});
	}

//#endregion

//#region 																							R E N D E R

	render() : React.ReactNode
	{
		return <>
			<IonModal isOpen={ this.props.isOpen } {...this.MdUserSearchModal___modal_config} handleBehavior="cycle">

				<IonHeader>
					<IonToolbar>
						<IonButtons slot="end">
							<IonButton onClick={() => { this.props.event___onDidDismiss(null); }}>
								<b>chiudi</b>
							</IonButton>
						</IonButtons>
					</IonToolbar>
					<IonListHeader>
						<IonLabel>
							<section style={{display:'flex',justifyContent:'space-between'}}>
								<span>
									<i className="fas fa-search" style={{paddingRight:'15pt'}}></i>
									<span>Cerca utente</span>
								</span>
							</section>
						</IonLabel>
					</IonListHeader>
					<section className="md-user-search---header">
						<label className="md-user-search---search-bar" htmlFor="input---discover-search-full">
							<i className="fas fa-search"></i>
							<input type="text" id="input---discover-search-full" placeholder="Cerca utente..." value={ this.state.users_search___param } onInput={(___e: any) => { this.handle___discover___search___input(___e.target.value); }}/>
						</label>
					</section>
				</IonHeader>

				<IonContent forceOverscroll={ false }>
					<section className="md-user-search---container">

						<br />

						{(this.state.users_search___param && this.state.users_search___param.length > 0) ? <>
						
							{(this.state.users_search___results.length > 0) ? <>
								<section>
									{this.state.users_search___results.map((___result: any, ___result___idx: number) => {
										return <>
											<section key={ ___result___idx } onClick={() => { this.props.event___onDidDismiss(___result['user_username']); }}>
												<ComUserComponent user___props={ ___result } is_logged={ this.props.is_logged } is_logged___props={ this.props.is_logged___props } />	
											</section>
											<ComListSeparatorComponent />
										</>;
									})}
								</section>
							</> : <></>}

							{(this.state.users_search___results___is_complete === true) ? <>
								<ComListEmptyComponent list_items_quantity={ this.state.users_search___results.length }/>
							</> : <>
								<ComInfiniteScrollerComponent is_loading={ this.state.users_search___results___is_loading } event___scrollTriggered={ this.read_props___search_results } />
							</>}
							
						</> : <>
							<br />
							<br />
							<br />
							<ComListEmptyComponent text="Nessun risultato..."/>
						</>}
					</section>
				</IonContent>

			</IonModal>
		</>;
	}

//#endregion

}