import { interface___is_logged } from "../../../interfaces/interface.logged";

//	P R O P S

export interface props___SbUsersProfilePage extends interface___is_logged {

}

//	S T A T E

export interface state___SbUsersProfilePage {

	tab___selected: 'feed' | 'tales' | 'favorites' | 'events',

	user___props___username: string | 'profile',
	user___props: any | null,
	user___props___is_loading: any | null,

	user___follows___is_loading: boolean,

	user___feed: any[],
	user___feed___is_loading: boolean,
	user___feed___is_complete: boolean
	
	user___tales: any[],
	user___tales___is_loading: boolean,
	user___tales___is_complete: boolean,

	user___events: any[],
	user___events___is_loading: boolean,
	user___events___is_complete: boolean,

	user___poi: any | null,
	user___poi___is_loading: boolean,
	
	ionModal___MdUserFollowsModal___isOpen: boolean,
	ionModal___MdUserFollowsModal___argument: 'following' | 'followers' | '',
	ionModal___MdMediaViewModal___isOpen: boolean,
	ionModal___MdMediaViewModal___argument: string,
	ionModal___MdTaleViewModal___isOpen: boolean,
	ionModal___MdTaleViewModal___argument: string,

}

//	S T A T E   D E F A U L T

export const state___SbUsersProfilePage___default: state___SbUsersProfilePage = {

	tab___selected: 'feed',

	user___props___username: '',
	user___props: null,
	user___props___is_loading: true,

	user___follows___is_loading: false,

	user___feed: [],
	user___feed___is_loading: true,
	user___feed___is_complete: false,

	user___tales: [],
	user___tales___is_loading: true,
	user___tales___is_complete: false,

	user___events: [],
	user___events___is_loading: true,
	user___events___is_complete: false,

	user___poi: null,
	user___poi___is_loading: true,

	ionModal___MdUserFollowsModal___isOpen: false,
	ionModal___MdUserFollowsModal___argument: '',
	ionModal___MdMediaViewModal___isOpen: false,
	ionModal___MdMediaViewModal___argument: '',
	ionModal___MdTaleViewModal___isOpen: false,
	ionModal___MdTaleViewModal___argument: '',

}