import React from 'react';
import { IonApp, IonRouterOutlet, IonToast, setupIonicReact } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { Redirect, Route } from 'react-router';
import { ActionPerformed, PushNotifications, Token } from '@capacitor/push-notifications';
import { AppTrackingTransparency, AppTrackingStatusResponse } from 'capacitor-plugin-app-tracking-transparency';

//	C S S   D E F A U L T S

import '@ionic/react/css/core.css';
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

//	T H E M E   F O N T S

import './theme/theme.fonts/font-awesome/css/all.min.css';
import './theme/theme.fonts/quicksand/quicksand.css';

//	T H E M E

import './theme/theme.reset.scss';
import './theme/theme.variables.scss';
import './theme/theme.global.scss';
import './theme/theme.mapbox.scss';

//	T Y P E S

import { type___api___response } from './types/types.api-response';
import { type___cookies___accepted } from './types/types.cookies';
import { type___geo___lngLat, type___user___interests } from './types/types.types';
import { type___locale___language } from './types/types.locale';

//	S T A T E   -   P R O P S

import { state___App, state___App___default } from './App.state';

//	L I B S

import { lib_names } from './libs/lib.names';

//	F U N C S

import { funcs_platform } from './funcs/funcs.platform';

//	S E R V I C E S

import { service_GeolocationService } from './services/service-geolocation';
import { service_InterestsService } from './services/service-interests';
import { service_RestAuthService } from './services/service-auth';
import { service_RestApiService } from './services/service-api';

//	R O U T E R S

import RouteProtected from './router/router-protected';
import RouteProtectedUnlogged from './router/router-protected-unlogged';
import RouteProtectedLogin from './router/router-protected-login';

//	P A G E S

import Sb404Page from './pages/sb-404/sb-404';

import SbArchivePage from './pages/sb-archive/sb-archive/sb-archive';
import SbArchiveEnrollPage from './pages/sb-archive/sb-archive-enroll/sb-archive-enroll';
import SbArchiveEventsPage from './pages/sb-archive/sb-archive-events/sb-archive-events';
import SbArchiveFavoritesPage from './pages/sb-archive/sb-archive-favorites/sb-archive-favorites';
import SbArchivePoiPage from './pages/sb-archive/sb-archive-poi/sb-archive-poi';
import SbArchiveNotificationsPage from './pages/sb-archive/sb-archive-notifications/sb-archive-notifications';

import SbCatalogCarsHubViewPage from './pages/sb-catalog-carshub/sb-catalog-carshub-view/sb-catalog-carshub-view';
import SbCatalogCarsHubVehicleViewPage from './pages/sb-catalog-carshub/sb-catalog-carshub-vehicle-view/sb-catalog-carshub-vehicle-view';

import SbChatsConversationPage from './pages/sb-chats/sb-chats-with/sb-chats-with';
import SbChatsListPage from './pages/sb-chats/sb-chats-list/sb-chats-list';

import SbDigitalMenuEditPage from './pages/sb-digitalmenus/sb-digitalmenu-edit/sb-digitalmenu-edit';

import SbDiscoverFeedPage from './pages/sb-discover/sb-discover-feed/sb-discover-feed';
import SbDiscoverHashtagPage from './pages/sb-discover/sb-discover-hashtag/sb-discover-hashtag';
import SbDiscoverMapPage from './pages/sb-discover/sb-discover-map/sb-discover-map';

import SbElementInvitePage from './pages/sb-elements/sb-element-invite/sb-element-invite';
import SbElementPostPage from './pages/sb-elements/sb-element-post/sb-element-post';
import SbElementTalePage from './pages/sb-elements/sb-element-tale/sb-element-tale';

import SbEventsEdit from './pages/sb-events/sb-events-edit/sb-events-edit';
import SbEventsViewPage from './pages/sb-events/sb-events-view/sb-events-view';
import SbEventViewNotificationsPage from './pages/sb-events/sb-events-view-notifications/sb-events-view-notifications';
import SbEventViewSettingsPage from './pages/sb-events/sb-events-view-settings/sb-events-view-settings';

import SbEventsCategoryViewPage from './pages/sb-events-category/sb-events-category-view/sb-events-category-view';

import SbInfoAppPage from './pages/sb-info/sb-info-app/sb-info-app';
import SbInfoCopyrightPage from './pages/sb-info/sb-info-copyright/sb-info-copyright';
import SbInfoDigitalMenuAllergensPage from './pages/sb-info/sb-info-digitalmenu-allergens/sb-info-digitalmenu-allergens';
import SbInfoEulaPage from './pages/sb-info/sb-info-eula/sb-info-eula';
import SbInfoFaqVerificationPage from './pages/sb-info/sb-info-faq-verification/sb-info-faq-verification';
import SbInfoLicencesPage from './pages/sb-info/sb-info-licences/sb-info-licences';
import SbInfoPartnersPage from './pages/sb-info/sb-info-partners/sb-info-partners';
import SbInfoPresentationPage from './pages/sb-info/sb-info-presentation/sb-info-presentation';
import SbInfoStatsPage from './pages/sb-info/sb-info-stats/sb-info-stats';
import SbInfoSuggestionsPage from './pages/sb-info/sb-info-suggestions/sb-info-suggestions';
import SbInfoSupportPage from './pages/sb-info/sb-info-support/sb-info-support';
import SbInfoThanksPage from './pages/sb-info/sb-info-thanks/sb-info-thanks';

import SbLandingGeolocationPage from './pages/sb-landing/sb-landing-geolocation/sb-landing-geolocation';
import SbLandingLoadingPage from './pages/sb-landing/sb-landing-loading/sb-landing-loading';

import SbLoginPage from './pages/sb-sign/sb-sign';

import SbPoiEditPage from './pages/sb-poi/sb-poi-edit/sb-poi-edit';
import SbPoiEditRolesPage from './pages/sb-poi/sb-poi-edit-roles/sb-poi-edit-roles';
import SbPoiViewPage from './pages/sb-poi/sb-poi-view/sb-poi-view';
import SbPoiViewDigitalMenuPage from './pages/sb-poi/sb-poi-view-digitalmenu/sb-poi-view-digitalmenu';
import SbPoiViewFeedPage from './pages/sb-poi/sb-poi-view-feed/sb-poi-view-feed';
import SbPoiViewSettingsPage from './pages/sb-poi/sb-poi-view-settings/sb-poi-view-settings';
import SbPoiViewTalesPage from './pages/sb-poi/sb-poi-view-tales/sb-poi-view-tales';

import SbPoiCategoryViewPage from './pages/sb-poi-category/sb-poi-category-view/sb-poi-category-view';

import SbSettingsAccountPage from './pages/sb-settings/sb-settings-account/sb-settings-account';
import SbSettingsAuthenticationPage from './pages/sb-settings/sb-settings-authentication/sb-settings-authentication';
import SbSettingsCreditsPage from './pages/sb-settings/sb-settings-credits/sb-settings-credits';
import SbSettingsDatasPage from './pages/sb-settings/sb-settings-datas/sb-settings-datas';
import SbSettingsDatasCachePage from './pages/sb-settings/sb-settings-datas-cache/sb-settings-datas-cache';
import SbSettingsDatasDeleteMePage from './pages/sb-settings/sb-settings-datas-delete-me/sb-settings-datas-delete-me';
import SbSettingsMainPage from './pages/sb-settings/sb-settings-main/sb-settings-main';
import SbSettingsPrivacyPage from './pages/sb-settings/sb-settings-privacy/sb-settings-privacy';
import SbSettingsProfilePage from './pages/sb-settings/sb-settings-profile/sb-settings-profile';

import SbUsersProfilePage from './pages/sb-user/sb-user-profile/sb-user-profile';

//	M O D A L S

import MdEntryCookiesModal from './modals/md-entry-cookies/md-entry-cookies';
import MdEntryDownloadAppModal from './modals/md-entry-download-app/md-entry-download-app';
import MdEntryInterestsModal from './modals/md-entry-interests/md-entry-interests';
import MdEntryLanguageModal from './modals/md-entry-language/md-entry-language';
import MdSignPasswordChangeModal from './modals/md-sign-password-change/md-sign-password-change';

//	S E T U P

setupIonicReact({
	mode: 'ios'
});

//	C L A S S

export default class App extends React.Component<{}, state___App> {

//#region 																							D E C L A R A T I O N S

	private _ABORT_CONTROLLER: AbortController | null = null;

	private readonly _AUTH: service_RestAuthService = new service_RestAuthService();
	private readonly _API: service_RestApiService = new service_RestApiService();
	private readonly _INTERESTS: service_InterestsService = new service_InterestsService();

	private readonly _GEOLOCATION: service_GeolocationService = new service_GeolocationService();

	private readonly funcs___platform: funcs_platform = new funcs_platform();

//#endregion

//#region 																							C O N S T R U C T O R

	constructor(
		public props: {},
	) {
		super(props);
		this.state = state___App___default;
		if (!this._ABORT_CONTROLLER) { this._ABORT_CONTROLLER = new AbortController(); }
	}

//#endregion

//#region 																							A B O R T   C O N T R O L L E R

	private AbortController___abort() : void {
		if (this._ABORT_CONTROLLER) { this._ABORT_CONTROLLER.abort(); }
	}

//#endregion

//#region 																							R E A D   P R O P S

	private read_props___me___is_logged = async () => {

		const ___me___is_logged___server_response: type___api___response = await this._AUTH.auth___checklogged();
		const ___me___is_logged: boolean = (___me___is_logged___server_response && ___me___is_logged___server_response['response'] && ___me___is_logged___server_response['response'] === 'success' && ___me___is_logged___server_response['data'] !== null) ? ___me___is_logged___server_response['data'] : false;

		const ___me___is_logged___props___server_response: type___api___response = await this._API.users___read___single('me', null);
		const ___me___is_logged___props: any | null = (___me___is_logged___props___server_response && ___me___is_logged___props___server_response['response'] && ___me___is_logged___props___server_response['response'] === 'success' && ___me___is_logged___props___server_response['data'] !== null) ? ___me___is_logged___props___server_response['data'] : null;

		return this.setState({
			me___is_logged: ___me___is_logged,
			me___is_logged___props: ___me___is_logged___props,
			app___is_loading: false,
		}, async () => {
			if (this.state.me___is_logged === true && this.state.me___is_logged___props !== null) {
				this.setState({ ionModal___MdSignPasswordChangeModal___isOpen: this.state.me___is_logged___props['user_password___is_temporary'] ?? false });
				this.notifications___register_receiver___push();
				await this.notifications___count();
				return;
			}
		});

	}

//#endregion

//#region 																							E N T R Y

	private entry___initialize = () => {
		const ___language___selected: type___locale___language | null = localStorage.getItem(lib_names.localStorage.user___language) as type___locale___language | null;
		this.setState({
			entry___language_selected: ___language___selected
		}, async () => {
			if (___language___selected === null) {
				this.setState({
					ionModal___MdEntryLanguageModal___isOpen: true
				});
			} else {
				if (this.funcs___platform.is_native() === true && this.funcs___platform.get_type() === 'ios') { await this.entry___requestTrackingPermission(); }
				const ___cookies___accepted: type___cookies___accepted = localStorage.getItem(lib_names.localStorage.user___cookies_accepted) as type___cookies___accepted;
				this.setState({
					entry___cookies_accepted: ___cookies___accepted
				}, () => {
					if (!(___cookies___accepted !== null)) { this.setState({ ionModal___MdEntryCookiesModal___isOpen: true }); }
				})
			}
		});
	}

	private entry___requestTrackingPermission = async () => {
		try {
			const ___tracking_status: AppTrackingStatusResponse = await AppTrackingTransparency.getStatus();			
			if (___tracking_status['status'] === 'notDetermined') {
				const ___tracking_status___result: AppTrackingStatusResponse = await AppTrackingTransparency.requestPermission();
				if (___tracking_status___result['status'] === 'authorized') {
					const ___tracking_enabled: type___cookies___accepted = 'full';
					localStorage.setItem(lib_names.localStorage.user___cookies_accepted, ___tracking_enabled);
				} else {
					const ___tracking_disabled: type___cookies___accepted = 'none';
					localStorage.setItem(lib_names.localStorage.user___cookies_accepted, ___tracking_disabled);
				}
			} else {
				console.log('Tracking permission status: ', ___tracking_status['status']);
			}
		} catch (___e: any) {
			console.error('Error requesting tracking permission', ___e);
		}
	};

	private entry___setup___language = (___l: type___locale___language) => {
		this.setState({
			ionModal___MdEntryLanguageModal___isOpen: false,
			entry___language_selected: ___l
		}, () => {
			this.entry___initialize();
		});
	}

	private entry___setup___cookies = (___ca: type___cookies___accepted) => {
		this.setState({
			ionModal___MdEntryCookiesModal___isOpen: false,
			entry___cookies_accepted: ___ca
		}, () => {
			this.entry___initialize();
		});
	}

	private entry___setup___interests = async () => {
		const ___user_iterests___temp: type___user___interests = await this._INTERESTS.get_interests();
		if (!(___user_iterests___temp['exists'] && ___user_iterests___temp['exists'] === true)) {
			this.setState({ ionModal___MdEntryInterestsModal___isOpen: true });
		}
	}

	private entry___geolocation = async () => {
		const ___geolocation___result: type___geo___lngLat = await this._GEOLOCATION.geolocation___locate();
		if (___geolocation___result['error'] && ___geolocation___result['error'] !== null) {
			this.setState({
				entry___geolocation_available: false,
				app___is_loading: false,
			});
		} else {
			this.setState({
				entry___geolocation_available: true,
				app___is_loading: false
			});
		}
	}

	private entry___download_app = () => {
		if (this.funcs___platform.is_native() === true) {
			localStorage.setItem(lib_names.localStorage.user___download_app, lib_names.localStorage.user___download_app)
		} else {
			const ___app_downloaded: string | null = localStorage.getItem(lib_names.localStorage.user___download_app);
			this.setState({
				ionModal___MdEntryDownloadAppModal___isOpen: (___app_downloaded) ? false : true,
			});
		}
	}

//#endregion

//#region 																							H A N D L E R S

	private handle___registerAuthUserDatas = (___user_props: any | null = null) => {
		this.setState({
			me___is_logged: (___user_props !== null) ? true : false,
			me___is_logged___props: ___user_props,
		}, async () => {
			if (this.state.me___is_logged === true && this.state.me___is_logged___props !== null) {
				this.setState({ ionModal___MdSignPasswordChangeModal___isOpen: this.state.me___is_logged___props['user_password___is_temporary'] ?? false });
				this.notifications___register_receiver___push();
				await this.notifications___count();
				return;
			}
		});
	}

//#endregion

//#region 																							N O T I F I C A T I O N S

	private notifications___register_receiver___push = () => {
		if (this.funcs___platform.is_native() === true) {

			PushNotifications.requestPermissions().then(result => {
				if (result.receive === 'granted') { PushNotifications.register(); }
				else { console.error('Push Notification permission denied'); }
			});
			
			PushNotifications.addListener('registration', (___token: Token) => {
				const ___registerd_token___platform: 'ios' | 'android' | null = this.funcs___platform.get_type();
				const ___registerd_token___value: string = ___token.value;
				(async () => {
					const ___registerd_token___value___registered: type___api___response = await this._API.notifications___client___register(___registerd_token___platform, ___registerd_token___value);
					if (___registerd_token___value___registered && ___registerd_token___value___registered.response === 'success') { localStorage.setItem(lib_names.localStorage.user___notification_token, ___registerd_token___value); }
				})();
			});

			PushNotifications.addListener('pushNotificationActionPerformed', (___notification: ActionPerformed) => {
				const ___notification_datas: any = ___notification.notification.data;
				if (___notification_datas && ___notification_datas.route) {
					const ___notification_datas___route: string = ___notification_datas.route;
					window.location.href = ___notification_datas___route;
				}
			});

			PushNotifications.addListener('registrationError', (___error: any) => {
				console.error('Error during notification-provider registration:', ___error);
			});

		}
	}

	private notifications___count = async () => {
		const ___notification_count___temp: type___api___response = await this._API.notifications___count();
		this.setState({
			notifications___count: (___notification_count___temp.response === 'success') ? ___notification_count___temp.data : 0,
		});
	}

//#endregion

//#region 																							L I F E C Y C L E

	async componentDidMount() : Promise<void> {
		await this.read_props___me___is_logged();
		this.AbortController___abort();
		this.entry___initialize();
		await Promise.all([
			this.entry___geolocation(),
			this.entry___setup___interests(),
		]);
		this.entry___download_app();
	}

	componentWillUnmount() : void {
		this.AbortController___abort();
	}

//#endregion

//#region 																							R E N D E R

	render() : React.ReactNode
	{
		return <>
			<IonApp>

				<IonToast isOpen={ this.state.ionToast___notification___isOpen } message={ this.state.ionToast___notification___message } duration={ 3000 } position="top" onDidDismiss={() => { this.setState({ ionToast___notification___isOpen: false }); }}/>
			
				<MdEntryDownloadAppModal isOpen={ this.state.ionModal___MdEntryDownloadAppModal___isOpen } event___onDidDismiss={() => { this.setState({ ionModal___MdEntryDownloadAppModal___isOpen: false }); }}/>

				{(this.state.app___is_loading === true) ? <>
					<SbLandingLoadingPage/>
				</> : <>
					{(this.state.entry___language_selected !== null && this.state.entry___cookies_accepted !== null) ? <>
						{(() => { switch (this.state.entry___geolocation_available) {
							case true: return <>
								<MdEntryInterestsModal isOpen={ this.state.ionModal___MdEntryInterestsModal___isOpen } isDismissible={ false } is_logged={ this.state.me___is_logged } is_logged___props={ this.state.me___is_logged___props } event___onDidDismiss={() => { this.setState({ ionModal___MdEntryInterestsModal___isOpen: false }); }}/>
								<MdSignPasswordChangeModal isOpen={ this.state.ionModal___MdSignPasswordChangeModal___isOpen } event___onDidDismiss={() => { this.setState({ ionModal___MdSignPasswordChangeModal___isOpen: false }); }}/>
								<IonReactRouter>
									<IonRouterOutlet>

										{/*      4 0 4      */}

										<Route path="/404" component={(___p: any) => { return <Sb404Page {...___p} /> }} exact />

										{/*      a r c h i v e      */}
									
										<RouteProtected isLogged={ this.state.me___is_logged } path="/archive" component={(___p: any) => { return <SbArchivePage {...___p} is_logged={ this.state.me___is_logged } is_logged___props={ this.state.me___is_logged___props } notification_count={ this.state.notifications___count } /> }} exact/>
										<RouteProtected isLogged={ this.state.me___is_logged } path="/archive/enroll" component={(___p: any) => { return <SbArchiveEnrollPage {...___p} is_logged={ this.state.me___is_logged } is_logged___props={ this.state.me___is_logged___props } /> }} exact/>
										<RouteProtected isLogged={ this.state.me___is_logged } path="/archive/events" component={(___p: any) => { return <SbArchiveEventsPage {...___p} is_logged={ this.state.me___is_logged } is_logged___props={ this.state.me___is_logged___props } /> }} exact />
										<RouteProtected isLogged={ this.state.me___is_logged } path="/archive/favorites" component={(___p: any) => { return <SbArchiveFavoritesPage {...___p} is_logged={ this.state.me___is_logged } is_logged___props={ this.state.me___is_logged___props } /> }} exact />
										<RouteProtected isLogged={ this.state.me___is_logged } path="/archive/poi" component={(___p: any) => { return <SbArchivePoiPage {...___p} is_logged={ this.state.me___is_logged } is_logged___props={ this.state.me___is_logged___props } /> }} exact />
										<RouteProtected isLogged={ this.state.me___is_logged } path="/archive/notifications" component={(___p: any) => { return <SbArchiveNotificationsPage {...___p} notification_count={ this.state.notifications___count } is_logged={ this.state.me___is_logged } is_logged___props={ this.state.me___is_logged___props }  /> }} exact />

										{/*      c a t a l o g      */}

										<Route path="/catalog/carshub/:id" component={(___p: any) => { return <SbCatalogCarsHubViewPage {...___p} /> }} exact />
										<Route path="/catalog/carshub/vehicle/:id" component={(___p: any) => { return <SbCatalogCarsHubVehicleViewPage {...___p} /> }} exact />

										{/*      c h a t      */}

										<RouteProtected isLogged={ this.state.me___is_logged } path="/chats/with/:id" component={(___p: any) => { return <SbChatsConversationPage {...___p} is_logged={ this.state.me___is_logged } is_logged___props={ this.state.me___is_logged___props } /> }} exact />
										<RouteProtected isLogged={ this.state.me___is_logged } path="/chats/list" component={(___p: any) => { return <SbChatsListPage {...___p} is_logged={ this.state.me___is_logged } is_logged___props={ this.state.me___is_logged___props } /> }} exact />

										{/*      d i g i t a l   m e n u      */}

										<RouteProtected isLogged={ this.state.me___is_logged } path="/digitalmenu/edit/:id" component={(___p: any) => { return <SbDigitalMenuEditPage {...___p} is_logged={ this.state.me___is_logged } is_logged___props={ this.state.me___is_logged___props } /> }} exact />

										{/*      d i s c o v e r      */}
									
										<RouteProtected isLogged={ this.state.me___is_logged } path="/discover/feed" component={(___p: any) => { return <SbDiscoverFeedPage {...___p} is_logged={ this.state.me___is_logged } is_logged___props={ this.state.me___is_logged___props } /> }} exact />
										<RouteProtected isLogged={ this.state.me___is_logged } path="/discover/hashtag/:id" component={(___p: any) => { return <SbDiscoverHashtagPage {...___p} is_logged={ this.state.me___is_logged } is_logged___props={ this.state.me___is_logged___props } /> }} exact />
										<Route path="/discover/map" component={(___p: any) => { return <SbDiscoverMapPage {...___p} is_logged={ this.state.me___is_logged } is_logged___props={ this.state.me___is_logged___props } notification_count={ this.state.notifications___count } /> }} exact />

										{/*      e l e m e n t s   s h o w      */}

										<RouteProtected isLogged={ this.state.me___is_logged } path="/show/invite/event/:id" component={(___p: any) => { return <SbElementInvitePage {...___p} is_logged={ this.state.me___is_logged } is_logged___props={ this.state.me___is_logged___props } /> }} exact />
										<RouteProtected isLogged={ this.state.me___is_logged } path="/show/post/:id" component={(___p: any) => { return <SbElementPostPage {...___p} is_logged={ this.state.me___is_logged } is_logged___props={ this.state.me___is_logged___props } /> }} exact />
										<RouteProtected isLogged={ this.state.me___is_logged } path="/show/tale/:id" component={(___p: any) => { return <SbElementTalePage {...___p} is_logged={ this.state.me___is_logged } is_logged___props={ this.state.me___is_logged___props } /> }} exact />

										{/*      e v e n t s      */}

										<RouteProtected isLogged={ this.state.me___is_logged } path="/edit/events/:id" component={(___p: any) => { return <SbEventsEdit {...___p} is_logged={ this.state.me___is_logged } is_logged___props={ this.state.me___is_logged___props }/> }} exact />
										<Route path="/view/events/:id" component={(___p: any) => { return <SbEventsViewPage {...___p} is_logged={ this.state.me___is_logged } is_logged___props={ this.state.me___is_logged___props } /> }} exact />
										<RouteProtected isLogged={ this.state.me___is_logged } path="/view/events/:id/settings" component={(___p: any) => { return <SbEventViewSettingsPage {...___p} is_logged={ this.state.me___is_logged } is_logged___props={ this.state.me___is_logged___props } /> }} exact />
										<RouteProtected isLogged={ this.state.me___is_logged } path="/view/events/:id/notifications" component={(___p: any) => { return <SbEventViewNotificationsPage {...___p} is_logged={ this.state.me___is_logged } is_logged___props={ this.state.me___is_logged___props } /> }} exact />

										{/*      e v e n t s   c a t e g o r y      */}
										
										<Route path="/view/events/category/:id" component={(___p: any) => { return <SbEventsCategoryViewPage {...___p} /> }} exact />

										{/*      i n f o      */}

										<Route path="/info/app" component={(___p: any) => { return <SbInfoAppPage {...___p} /> }} exact />
										<Route path="/info/copyright" component={(___p: any) => { return <SbInfoCopyrightPage {...___p} /> }} exact />
										<Route path="/info/digitalmenu/allergens" component={(___p: any) => { return <SbInfoDigitalMenuAllergensPage {...___p} /> }} exact />
										<Route path="/info/eula" component={(___p: any) => { return <SbInfoEulaPage {...___p} /> }} exact />
										<Route path="/info/faq/verification" component={(___p: any) => { return <SbInfoFaqVerificationPage {...___p} /> }} exact />
										<Route path="/info/licences" component={(___p: any) => { return <SbInfoLicencesPage {...___p} /> }} exact />
										<Route path="/info/partners" component={(___p: any) => { return <SbInfoPartnersPage {...___p} /> }} exact />
										<RouteProtectedUnlogged isLogged={ this.state.me___is_logged } path="/info/presentation" component={(___p: any) => { return <SbInfoPresentationPage {...___p} is_logged={ this.state.me___is_logged } is_logged___props={ this.state.me___is_logged___props } /> }} exact />
										<Route path="/info/stats" component={(___p: any) => { return <SbInfoStatsPage {...___p} /> }} exact />
										<RouteProtected isLogged={ this.state.me___is_logged } path="/info/suggestions" component={(___p: any) => { return <SbInfoSuggestionsPage {...___p} is_logged={ this.state.me___is_logged } is_logged___props={ this.state.me___is_logged___props } /> }} exact />
										<Route path="/info/support" component={(___p: any) => { return <SbInfoSupportPage {...___p} /> }} exact />
										<Route path="/info/thanks" component={(___p: any) => { return <SbInfoThanksPage {...___p} /> }} exact />

										{/*      p o i      */}

										<Route path="/edit/poi/:id" component={(___p: any) => { return <SbPoiEditPage {...___p} is_logged={ this.state.me___is_logged } is_logged___props={ this.state.me___is_logged___props }/> }} exact />
										<Route path="/edit/poi/:id/roles" component={(___p: any) => { return <SbPoiEditRolesPage {...___p} is_logged={ this.state.me___is_logged } is_logged___props={ this.state.me___is_logged___props }/> }} exact />
										<Route path="/view/poi/:id" component={(___p: any) => { return <SbPoiViewPage {...___p} is_logged={ this.state.me___is_logged } is_logged___props={ this.state.me___is_logged___props } /> }} exact />
										<Route path="/view/poi/:id/digitalmenu" component={(___p: any) => { return <SbPoiViewDigitalMenuPage {...___p} is_logged={ this.state.me___is_logged } is_logged___props={ this.state.me___is_logged___props } /> }} exact />
										<Route path="/view/poi/:id/feed" component={(___p: any) => { return <SbPoiViewFeedPage {...___p} is_logged={ this.state.me___is_logged } is_logged___props={ this.state.me___is_logged___props } /> }} exact />
										<RouteProtected isLogged={ this.state.me___is_logged } path="/view/poi/:id/settings" component={(___p: any) => { return <SbPoiViewSettingsPage {...___p} /> }} exact />
										<RouteProtected isLogged={ this.state.me___is_logged } path="/view/poi/:id/tales" component={(___p: any) => { return <SbPoiViewTalesPage {...___p} /> }} exact />

										{/*      p o i   c a t e g o r y      */}

										<Route path="/view/poi/category/:id" component={(___p: any) => { return <SbPoiCategoryViewPage {...___p} /> }} exact />

										{/*      s i g n      */}

										<RouteProtectedLogin isLogged={ this.state.me___is_logged } path="/sign" component={(___p: any) => { return <SbLoginPage {...___p} event___registerAuthUserDatas={(___user_props: boolean) => { this.handle___registerAuthUserDatas(___user_props); }}/> }} exact />

										{/*      s e t t i n g s      */}

										<RouteProtected isLogged={ this.state.me___is_logged } path="/settings" component={(___p: any) => { return <SbSettingsMainPage {...___p} is_logged={ this.state.me___is_logged } is_logged___props={ this.state.me___is_logged___props } /> }} exact />
										<RouteProtected isLogged={ this.state.me___is_logged } path="/settings/auth" component={(___p: any) => { return <SbSettingsAuthenticationPage {...___p} /> }} exact />
										<RouteProtected isLogged={ this.state.me___is_logged } path="/settings/account" component={(___p: any) => { return <SbSettingsAccountPage {...___p} /> }} exact />
										<RouteProtected isLogged={ this.state.me___is_logged } path="/settings/credits" component={(___p: any) => { return <SbSettingsCreditsPage {...___p} /> }} exact />
										<RouteProtected isLogged={ this.state.me___is_logged } path="/settings/datas" component={(___p: any) => { return <SbSettingsDatasPage {...___p} /> }} exact />
										<Route path="/settings/datas/cache" component={(___p: any) => { return <SbSettingsDatasCachePage {...___p} /> }} exact />
										<RouteProtected isLogged={ this.state.me___is_logged } path="/datas/delete-me" component={(___p: any) => { return <SbSettingsDatasDeleteMePage {...___p} is_logged={ this.state.me___is_logged } is_logged___props={ this.state.me___is_logged___props } /> }} exact />
										<RouteProtected isLogged={ this.state.me___is_logged } path="/settings/privacy" component={(___p: any) => { return <SbSettingsPrivacyPage {...___p} /> }} exact />
										<RouteProtected isLogged={ this.state.me___is_logged } path="/settings/profile" component={(___p: any) => { return <SbSettingsProfilePage {...___p} /> }} exact />

										{/*      u s e r      */}

										<RouteProtected isLogged={ this.state.me___is_logged } path="/user/profile" component={(___p: any) => { return <SbUsersProfilePage {...___p}  is_logged={ this.state.me___is_logged } is_logged___props={ this.state.me___is_logged___props } /> }} exact />
										<RouteProtected isLogged={ this.state.me___is_logged } path="/view/user/:id" exact component={(___p: any) => { return <SbUsersProfilePage {...___p} is_logged={ this.state.me___is_logged } is_logged___props={ this.state.me___is_logged___props } exact /> }} />

										{/*      r e d i r e c t      */}
										
										<Redirect to="/discover/map" />

									</IonRouterOutlet>
								</IonReactRouter>
							</>; break;
							case false: return <>
								<SbLandingGeolocationPage event___onGeolocationAvailable={() => { this.entry___geolocation(); }}/>
							</>; break;
							default: case null: return <>
								<SbLandingLoadingPage/>
							</>; break;
						}})()}
					</> : <>
						<MdEntryCookiesModal isOpen={ this.state.ionModal___MdEntryCookiesModal___isOpen } event___onDidDismiss={ this.entry___setup___cookies }/>
						<MdEntryLanguageModal isOpen={ this.state.ionModal___MdEntryLanguageModal___isOpen } isDismissible={ false }  event___onDidDismiss={ this.entry___setup___language }/>
						<SbLandingLoadingPage/>
					</>}
				</>}

			</IonApp>
		</>;
	}

//#endregion

}