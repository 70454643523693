import React from 'react';
import { IonAlert, IonBackButton, IonButtons, IonCard, IonContent, IonHeader, IonItem, IonLabel, IonListHeader, IonPage, IonToast, IonToolbar, withIonLifeCycle } from '@ionic/react';

//	L O C A L I Z A T I O N S

import { locale___App } from '../../../App.locale';

//	S T Y L E

import './sb-settings-datas-delete-me.scss';

//	I N T E R F A C E S

import { interface___IonAlert } from '../../../interfaces/interface.alert';

//	T Y P E S

import { type___api___response } from '../../../types/types.api-response';

//	P R O P S   -   S T A T E

import { props___SbSettingsDatasDeleteMePage, state___SbSettingsDatasDeleteMePage, state___SbSettingsDatasDeleteMePage___default } from './sb-settings-datas-delete-me.state';

//	S E R V I C E S

import { service_LocalizationService } from '../../../services/service-localization';
import { service_RestApiService } from '../../../services/service-api';

//	C O M P O N E N T S

import ComSpinnerComponent from '../../../components/com-spinner/com-spinner';

//	C L A S S

class SbSettingsDatasDeleteMePage extends React.Component<props___SbSettingsDatasDeleteMePage, state___SbSettingsDatasDeleteMePage>
{

//#region 																							D E C L A R A T I O N S

	private readonly _LOCALE: service_LocalizationService = new service_LocalizationService();
	private readonly _API: service_RestApiService = new service_RestApiService();

//#endregion

//#region 																							C O N F I G S

	private readonly ionAlert___askPasswordDelete___config: interface___IonAlert = {
		header: 'Inserisci la tua password per confermare',
		message: '',
		inputs: [
			{
				name: 'password',
				type: 'password',
				placeholder: '**************',
			}
		],
		buttons: [
			{
				text: 'Accetta',
				handler: (___alertData: any) => {
					this.setState({
						delete___is_loading: true,
					}, async () => {
						await this.handle___delete_me(___alertData['password']);
					});
				}
			},
			{
				text: 'Annulla',
				role: 'cancel',
				handler: () => {
					this.setState({ ionAlert___askPasswordDelete___isOpen: false });
				}
			}
		]
	};

//#endregion

//#region 																							C O N S T R U C T O R

	constructor(
		public props: props___SbSettingsDatasDeleteMePage,
	) {
		super(props);
		this.state = state___SbSettingsDatasDeleteMePage___default;
	}

//#endregion

//#region 																							H A N D L E R S

	private handle___delete_me = async (___password: string) => {
		const ___user_is_deleted: type___api___response = await this._API.auth_delete_account___delete_account(___password);
		if (___user_is_deleted && ___user_is_deleted['response'] && ___user_is_deleted['response'] === 'success') {
			this.setState({
				ionToast___deleteAccountSuccess___isVisible: true,
				ionToast___deleteAccountError___isVisible: false,
			}, () => {
				localStorage.clear();
				window.location.href = '/';
			});
		} else {
			this.setState({
				ionToast___deleteAccountSuccess___isVisible: false,
				ionToast___deleteAccountError___isVisible: true,
			});
		}
	}

//#endregion

//#region 																							R E T U R N

	render() : React.ReactNode
	{
		return <>
			<IonPage>

				<IonAlert isOpen={ this.state.ionAlert___askPasswordDelete___isOpen } { ...this.ionAlert___askPasswordDelete___config} />

				<IonToast isOpen={ this.state.ionToast___deleteAccountError___isVisible } message="Errore nell'eliminazione dell'account, riprova più tardi o contatta l'assistenza" duration={ 3000 } color="danger" position="top" onDidDismiss={() => { this.setState({ ionToast___deleteAccountError___isVisible: false }); }}/>
				<IonToast isOpen={ this.state.ionToast___deleteAccountSuccess___isVisible } message="Account in via di eliminazione" duration={ 3000 } position="top" onDidDismiss={() => { this.setState({ ionToast___deleteAccountSuccess___isVisible: false }); }}/>

				<IonHeader className="ion-no-border" collapse="fade">
					<IonToolbar>
						<IonButtons slot="start">
							<IonBackButton defaultHref="/discover" text={ this._LOCALE.translate(locale___App.element___IonBackButton) }></IonBackButton>
						</IonButtons>
					</IonToolbar>
				</IonHeader>

				<IonContent forceOverscroll={ false }>
					{(this.state.delete___is_loading === true) ? <>
						<br />
						<br />
						<br />
						<br />
						<ComSpinnerComponent size="standard"/>
					</> : <>
						<section className="sb-settings-delete-me---container">

							<br />

							<img src="../../assets/ui/images/image-helpdesk.svg"/>

							<br />
							<br />

							<IonListHeader>
								<IonLabel style={{textAlign:'center'}}>Ci dispiace che tu voglia andare via</IonLabel>
							</IonListHeader>

							<br />

							<p>Se vuoi eliminare il tuo account puoi procedere cliccando il bottone qui sotto</p>
							<p><b>Questa azione è irreversibile</b></p>

							<br />

							<p style={{opacity:'0.5',fontSize:'10pt'}}>Per questioni tecniche potrebbero essere necessari fino a 30 giorni prima della completa eliminazione di tutti i tuoi dati</p>

							<br />
							<br />

							{(this.state.delete___confirmation === false) ? <>

								<IonCard color="danger">
									<IonItem color="danger" className="is-ion-item-button" button={ true } onClick={() => { this.setState({ delete___confirmation: true}); }}>
										<i className="fas fa-user-slash"></i>
										<IonLabel style={{textAlign:'center'}}>Elimina il tuo account</IonLabel>
									</IonItem>
								</IonCard>

							</> : <>

								<IonCard style={{opacity:'0.5'}}>
									<IonItem className="is-ion-item-button" button={ true }>
										<i className="fas fa-user-slash"></i>
										<IonLabel style={{textAlign:'center'}}>Vuoi eliminare il tuo account</IonLabel>
									</IonItem>
								</IonCard>

								<br />

								<IonCard color="danger">
									<IonItem color="danger" className="is-ion-item-button" button={ true }  onClick={() => { this.setState({ ionAlert___askPasswordDelete___isOpen: true}); }}>
										<i className="fas fa-user-slash"></i>
										<IonLabel style={{textAlign:'center'}}><b>Si, sono sicuro</b></IonLabel>
									</IonItem>
								</IonCard>

							</>}

						</section>
					</>}
				</IonContent>

			</IonPage>
		</>;

	}

//#endregion

}

//	E X P O R T

export default withIonLifeCycle(SbSettingsDatasDeleteMePage);