import React from 'react'
import { Link } from 'react-router-dom';
import { IonCard, IonItem, IonLabel } from '@ionic/react';

//	L O C A L I Z A T I O N

import { locale___MdDiscoverCompassComponent } from './md-discover-compass.locale';

//	S T Y L E

import './md-discover-compass.scss'

//	S T A T E   -   P R O P S 

import { props___MdDiscoverCompassComponent, state___MdDiscoverCompassComponent, state___MdDiscoverCompassComponent___default } from './md-discover-compass.state';

//	T Y P E S

import { type___api___response } from '../../types/types.api-response';

//	S E R V I C E S

import { service_LocalizationService } from '../../services/service-localization';
import { service_RestApiService } from '../../services/service-api';

//	C O M P O N E N T S

import ComEventCategoryComponent from '../../components/com-event-category/com-event-category';
import ComHeaderLogoComponent from '../../components/com-header-logo/com-header-logo';
import ComPoiCategoryComponent from '../../components/com-poi-category/com-poi-category';
import ComSpinnerInlineComponent from '../../components/com-spinner-inline/com-spinner-inline';

//	C L A S S

export default class MdDiscoverCompassComponent extends React.Component<props___MdDiscoverCompassComponent, state___MdDiscoverCompassComponent>
{

//#region 																							D E C L A R A T I O N S

	private readonly _API: service_RestApiService = new service_RestApiService();
	private readonly _LOCALE: service_LocalizationService = new service_LocalizationService();

	private compass_wheel_element: React.RefObject<HTMLDivElement> = React.createRef<HTMLDivElement>();
	private compass_wheel_element___rotation: number = 0;
	private compass_wheel_element___rotation_step___index: number = 0;
	private compass_wheel_element___rotation_step___index___max: number = 0;
	private compass_wheel_element___rotation_step___multiplier: number = 0;

//#endregion

//#region 																							C O N S T R U C T O R

	constructor(
		public props: props___MdDiscoverCompassComponent,
	) {
		super(props);
		this.state = state___MdDiscoverCompassComponent___default;
	}

//#endregion

//#region 																							H A N D L E R S

	private handle___compass_wheel___prepare = () => {
		this._API.poi_categories___read___multi(null).then((___categories_primary___collection: type___api___response) => {

			const ___categories_primary___parsed: any[] = ___categories_primary___collection.data.map((___c_p: any) => { return {...___c_p, 'category_contains_events': false }});

			this.setState({
				categories_primary___collection: [{ 'category_contains_events': true }, ...___categories_primary___parsed]
			}, async () => {
	
				const ___compass_wheel: HTMLDivElement = this.compass_wheel_element.current as HTMLDivElement;
				const ___compass_wheel___buttons: HTMLCollection = ___compass_wheel.children as HTMLCollection;
		
				this.compass_wheel_element___rotation_step___index___max = ___compass_wheel___buttons.length;
				this.compass_wheel_element___rotation_step___multiplier = (360 / this.compass_wheel_element___rotation_step___index___max);
	
				for (let ___wheel_b___i = 0; ___wheel_b___i < this.compass_wheel_element___rotation_step___index___max; ___wheel_b___i++)
				{
					
					const ___compass_wheel___button_single: HTMLElement | null = ___compass_wheel___buttons.item(___wheel_b___i)! as HTMLElement;
					const ___compass_wheel___button_single___rotation: number = this.compass_wheel_element___rotation_step___multiplier * ___wheel_b___i;
	
					___compass_wheel___button_single!.style.transform = 'rotate(' + ___compass_wheel___button_single___rotation + 'deg)';
			
				}
				
				this.handle___compass_wheel___check_item();
	
			});

		});
	}

	private handle___compass_wheel___rotate = (___direction: 'prev' | 'next') => {

		switch (___direction) {
			case 'prev':
				this.compass_wheel_element___rotation_step___index = (this.compass_wheel_element___rotation_step___index - 1 + this.compass_wheel_element___rotation_step___index___max) % this.compass_wheel_element___rotation_step___index___max;
				this.compass_wheel_element___rotation = this.compass_wheel_element___rotation - this.compass_wheel_element___rotation_step___multiplier;
			break;
			case 'next':
				this.compass_wheel_element___rotation_step___index = (this.compass_wheel_element___rotation_step___index + 1) % this.compass_wheel_element___rotation_step___index___max;
				this.compass_wheel_element___rotation = this.compass_wheel_element___rotation + this.compass_wheel_element___rotation_step___multiplier;
			break;
		}

		const ___compass_wheel_element___rotation___stringified: string = this.compass_wheel_element___rotation.toString();
		this.compass_wheel_element.current.style.transform = 'rotate(calc(-1 * ' + ___compass_wheel_element___rotation___stringified + 'deg)';

		this.handle___compass_wheel___check_item();

	}

	private handle___compass_wheel___check_item = () => {

		const ___temp___categories_primary___collection: any[] = this.state.categories_primary___collection;

		this.setState({
			categories_primary___selected: ___temp___categories_primary___collection[this.compass_wheel_element___rotation_step___index],
			categories_secondary___collection: [],
		}, async () => {
			if (this.state.categories_primary___selected['category_contains_events'] && this.state.categories_primary___selected['category_contains_events'] === true) {
				const ___category_selected___props: type___api___response = await this._API.events_categories___read___multi();
				this.setState({
					categories_secondary___collection: [],
					categories_secondary___events___collection: ___category_selected___props.data,
				});
			} else {
				const ___category_selected___props: type___api___response = await this._API.poi_categories___read___multi(this.state.categories_primary___selected['poi_category_id']);
				this.setState({
					categories_secondary___collection: ___category_selected___props.data,
					categories_secondary___events___collection: []
				});
			}
		});

	}

//#endregion

//#region 																							L I F E C Y C L E

	componentDidUpdate(prevProps: props___MdDiscoverCompassComponent) : void {
		if (this.props.isOpen !== prevProps.isOpen && this.props.isOpen === true) {
			this.handle___compass_wheel___prepare();
		}
	}

//#endregion

//#region 																							R E N D E R

	render(): React.ReactNode
	{
		return <>
			{(this.props && this.props.isOpen && this.props.isOpen === true) ? <>
				<section className="md-discover-compass---container">

					<section className="md-discover-compass---header">
						<ComHeaderLogoComponent logo___theme="light"/>
					</section>

					<div className="md-discover-compass---categories-subs">
						{(this.state.categories_primary___selected !== null) ? <>
							<section>
								{(this.state.categories_primary___selected['category_contains_events'] && this.state.categories_primary___selected['category_contains_events'] === true) ? <>
									<IonCard>
										<IonItem key={ 9039387381 } className="is-ion-item-button" routerLink={ '/view/events/category/all' } routerDirection="forward" onClick={() => { this.props.event___onDidDismiss(); }}>
											<img src={ '/assets/markers/event.svg' } />
											<IonLabel><b>{ this._LOCALE.translate(locale___MdDiscoverCompassComponent.show_all_events) }</b></IonLabel>
										</IonItem>
									</IonCard>
									{(this.state.categories_secondary___events___collection.length > 0) ? <>
										{this.state.categories_secondary___events___collection.map((___c_s_e: any, ___c_s_e___idx: number) => { return <>
											<Link key={ ___c_s_e___idx } to={ '/view/events/category/' + ___c_s_e['event_category_id'] } onClick={() => { this.props.event___onDidDismiss(); }}>
												<IonCard>
													<ComEventCategoryComponent key={ ___c_s_e___idx } event_category___props={ ___c_s_e }  isButton={ true }/>
												</IonCard>
											</Link>
										</> })}
									</> : <>
										<ComSpinnerInlineComponent/>
									</>}
								</> : <>
									{(this.state.categories_secondary___collection.length > 0) ? <>
										{this.state.categories_secondary___collection.map((___c_s: any, ___c_s___idx: number) => { return <>
											<Link key={ ___c_s___idx } to={ '/view/poi/category/' + ___c_s['poi_category_id'] } onClick={() => { this.props.event___onDidDismiss(); }}>
												<IonCard>
													<ComPoiCategoryComponent key={ ___c_s___idx } poi_category___props={ ___c_s }  isButton={ true }/>
												</IonCard>
											</Link>
										</> })}
									</> : <>
										<ComSpinnerInlineComponent/>
									</>}
								</>}
							</section>
						</> : <>
							<section>
								<ComSpinnerInlineComponent/>
							</section>
						</>}
					</div>

					<div className="md-discover-compass---compass">
						<div ref={ this.compass_wheel_element } className="md-discover-compass---compass---wheel">
							{this.state.categories_primary___collection.map((___c_p: any, ___c_p___idx: number) => {
								return <>
									{(___c_p['category_contains_events'] && ___c_p['category_contains_events'] === true) ? <>
										<div key={ ___c_p___idx } className="md-discover-compass---compass---wheel-element">
											<div className="is-element is-card no-padding wheel---element---button">
												<img src={ '/assets/markers/event.svg' } />
											</div>
											<div>{ this._LOCALE.translate(locale___MdDiscoverCompassComponent.category_events) }</div>
										</div>
									</> : <>
										<div key={ ___c_p___idx } className="md-discover-compass---compass---wheel-element">
											<div className="is-element is-card no-padding wheel---element---button">
												<img src={ '/assets/markers/poi/' + ___c_p['poi_category_icon'] + '.svg' } />
											</div>
											<div>{ ___c_p['poi_category_name'] }</div>
										</div>
									</>}
								</>;
							})}
						</div>
						<div className="md-discover-compass---compass---arrow" style={{left:'0pt'}} onClick={() => { this.handle___compass_wheel___rotate('prev'); }}>
							<i className="fas fa-chevron-left" style={{textAlign:'right'}}></i>
						</div>
						<section className="md-discover-compass---compass---close" onClick={() => { this.props.event___onDidDismiss(); }}>
							<i className="is-element is-card fas fa-times"></i>
						</section>
						<div className="md-discover-compass---compass---arrow" style={{right:'0pt'}} onClick={() => { this.handle___compass_wheel___rotate('next'); }}>
							<i className="fas fa-chevron-right" style={{textAlign:'left'}}></i>
						</div>
					</div>

				</section>
			</> : <></>}
		</>;
	}

//#endregion

}