import React from 'react';
import { IonBackButton, IonButtons, IonCard, IonContent, IonHeader, IonItem, IonLabel, IonList, IonListHeader, IonPage, IonToolbar, withIonLifeCycle } from '@ionic/react';

//	L O C A L I Z A T I O N S

import { locale___App } from '../../../App.locale';
import { locale___SbInfoSupportPage } from './sb-info-support.locale';

//	S T Y L E

import './sb-info-support.scss';

//	T Y P E S

import { type___api___response } from '../../../types/types.api-response';

//	S E R V I C E S

import { service_LocalizationService } from '../../../services/service-localization';
import { service_RestApiService } from '../../../services/service-api';
import { Link } from 'react-router-dom';

//	C L A S S

class SbInfoSupportPage extends React.Component<{}, {}>
{

//#region 																							D E C L A R A T I O N S

	private readonly _LOCALE: service_LocalizationService = new service_LocalizationService();
	private readonly _API: service_RestApiService = new service_RestApiService();

//#endregion

//#region 																							C O N S T R U C T O R

	constructor(
		public props: {},
	) {
		super(props);
	}

//#endregion

//#region 																							R E A D   P R O P S

	private read_props___stats___platform = async () => {
		const ___temp___stats___platform: type___api___response = await this._API.stats___platform();
		if (___temp___stats___platform.response === 'success') {
			this.setState({
				stats_platform___count_events: (___temp___stats___platform.data['count_events']) ?? null, 
				stats_platform___count_poi: (___temp___stats___platform.data['count_poi']) ?? null, 
				stats_platform___count_poi_categories: (___temp___stats___platform.data['count_poi_categories']) ?? null, 
				stats_platform___count_posts: (___temp___stats___platform.data['count_posts']) ?? null, 
				stats_platform___count_tales: (___temp___stats___platform.data['count_tales']) ?? null, 
				stats_platform___count_users: (___temp___stats___platform.data['count_users']) ?? null, 
				stats_platform___props___is_loading: false,
			});
		}
	}

//#endregion

//#region 																							L I F E C Y C L E

	async ionViewDidEnter() : Promise<void>
	{
		await this.read_props___stats___platform();
	}

//#endregion

//#region 																							R E T U R N

	render() : React.ReactNode
	{
		return <>
			<IonPage>

				<IonHeader className="ion-no-border" collapse="fade">
					<IonToolbar>
						<IonButtons slot="start">
							<IonBackButton defaultHref="/discover" text={ this._LOCALE.translate(locale___App.element___IonBackButton) }></IonBackButton>
						</IonButtons>
					</IonToolbar>
				</IonHeader>

				<IonContent forceOverscroll={ false }>
					<section className="sb-info-support---container">

						<img src="../../assets/ui/images/image-helpdesk.svg"/>

						<br />
						<br />

						<IonListHeader>
							<IonLabel style={{textAlign:'center'}}>{ this._LOCALE.translate(locale___SbInfoSupportPage.support___title) }</IonLabel>
						</IonListHeader>

						<br />

						<p>{ this._LOCALE.translate(locale___SbInfoSupportPage.support___paragraph) }</p>
						<p>L'assistenza risponde di solito entro 48 ore</p>

						<br />

						<IonCard>
							<a href="mailto:support@nearbycommunity.it" target="_blank">
								<IonItem className="is-ion-item-button" button={ true }>
									<i className="fas fa-envelope"></i>
									<IonLabel>support@nearbycommunity.it</IonLabel>
								</IonItem>
							</a>
						</IonCard>
					
						<br />
						<br />

						<IonListHeader>
							<IonLabel style={{textAlign:'center'}}>{ this._LOCALE.translate(locale___SbInfoSupportPage.socials___title) }</IonLabel>
						</IonListHeader>

						<br />

						<IonCard>
							<IonList>
								<a href="https://www.facebook.com/nearbycommunity/" target="_blank">
									<IonItem className="is-ion-item-button" button={ true }>
										<i className="fab fa-facebook" style={{opacity:1}}></i>
										<IonLabel>Facebook</IonLabel>
									</IonItem>
								</a>
								<a href="https://www.instagram.com/nearbycommunity/" target="_blank">
									<IonItem className="is-ion-item-button" button={ true }>
										<i className="fab fa-instagram" style={{opacity:1}}></i>
										<IonLabel>Instagram</IonLabel>
									</IonItem>
								</a>
								<a href="https://www.linkedin.com/company/nearbycommunity" target="_blank">
									<IonItem className="is-ion-item-button" button={ true }>
										<i className="fab fa-linkedin" style={{opacity:1}}></i>
										<IonLabel>LinkedIn</IonLabel>
									</IonItem>
								</a>







							</IonList>
						</IonCard>


						<br />
						<br />

						<IonListHeader>
							<IonLabel style={{textAlign:'center'}}>Elimina i tuoi dati</IonLabel>
						</IonListHeader>

						<br />

						<IonCard color="danger">
							<IonItem color="danger" className="is-ion-item-button" button={ true } routerLink="/datas/delete-me">
								<i className="fas fa-user-slash"></i>
								<IonLabel>Elimina il tuo account ed i tuoi dati</IonLabel>
							</IonItem>
						</IonCard>

					</section>
				</IonContent>

			</IonPage>
		</>;

	}

//#endregion

}

//	E X P O R T

export default withIonLifeCycle(SbInfoSupportPage);